import { Injectable } from '@angular/core';
import { AngularFireFunctions } from "@angular/fire/compat/functions";
import { map, Observable } from "rxjs";
import { LocalStorageService } from "../../home/services/local-storage.service";
import { Profile, Settings } from '../model/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private functions: AngularFireFunctions,
    private localStorageService: LocalStorageService,
  ) {
  }

  isCompanySubscriptionValid(): Observable<boolean> {
    const isCompanySubscriptionValid = this.functions.httpsCallable('isCompanySubscriptionValid');
    return isCompanySubscriptionValid({
      companyId: this.localStorageService.getCompanyId(),
      accessToken: this.localStorageService.getAccessToken()
    });
  }

  hasAccessToPaidFeatures(): Observable<boolean> {
    const isCompanySubscriptionValid = this.functions.httpsCallable('hasAccessToPaidFeatures');
    return isCompanySubscriptionValid({
      companyId: this.localStorageService.getCompanyId(),
      accessToken: this.localStorageService.getAccessToken()
    });
  }

  getCheckoutSessionURL(monthly: boolean): Observable<string> {
    const getCheckoutSessionURL = this.functions.httpsCallable('getCheckoutSessionURL');
    return getCheckoutSessionURL({
      monthly,
      companyId: this.localStorageService.getCompanyId(),
      accessToken: this.localStorageService.getAccessToken()
    });
  }

  saveReferralCode(referral: string): Observable<boolean> {
    const saveReferralCode = this.functions.httpsCallable('saveReferralCode');
    return saveReferralCode({
      referral,
      accessToken: this.localStorageService.getAccessToken()
    });
  }

  getUserProfile(): Observable<Profile> {
    const getUserProfile = this.functions.httpsCallable('getUserProfile');
    return getUserProfile({
      accessToken: this.localStorageService.getAccessToken()
    });
  }

  saveUserSettings(settings: Partial<Settings>): Observable<Partial<Settings>> {
    const saveUserSettings = this.functions.httpsCallable('saveUserSettings');
    return saveUserSettings({
      settings,
      accessToken: this.localStorageService.getAccessToken()
    }).pipe(map(() => settings));
  }
}
