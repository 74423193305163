<app-header [title]="title$ | async"></app-header>
<ng-container *ngIf="cashFlowGraphData$ | async as cashFlowGraphData">
  <ng-container *transloco="let t; read: 'valuation'">
    <div class="indicators">
      <app-mini-chart [name]="t('intrinsic.title')" [dataSource]="cashFlowGraphData.input" [yearMonths]="yearMonths$ | async"
                      [color]="valuationMode === 'intrinsic' ? enabledColor : disabledColor" [cumulated]="true"></app-mini-chart>
      <app-mini-chart [name]="t('performance.title')" [dataSource]="cashFlowGraphData.output" [yearMonths]="yearMonths$ | async"
                      [color]="valuationMode === 'performance' ? enabledColor : disabledColor" [cumulated]="true"></app-mini-chart>
      <app-mini-chart [name]="t('practitioners.title')" [dataSource]="cashFlowGraphData.result" [yearMonths]="yearMonths$ | async"
                      [color]="valuationMode === 'practitioners' ? enabledColor : disabledColor" [cumulated]="true"></app-mini-chart>
      <div class="coming-soon">
        <span class="title">{{ t('beta.title') }}</span>
        <span>{{ t('beta.message') }}</span>
      </div>
    </div>

    <main>
      <nav>
        <div class="container">
          <div>
            <p class="message-info">{{ t('label-methods') }}</p>
            <div class="container-left">
              <mat-button-toggle-group [(ngModel)]="valuationMode"
                                       name="modeValuationMethode" aria-label="{{ t('label-methods') }}">
                <mat-button-toggle *ngFor="let valuationModeOption of valuationModeList"
                                   [value]="valuationModeOption"
                                   [disabled]="valuationModeOption !== valuationMode">
                  {{ t('methods', { method: valuationModeOption }) }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
      </nav>
      <div style="display: none" class="container benchmark-container">
        <span class="message-info">t('graph-criteria')</span>
        <div class="benchmark-criterias">
          <mat-icon>circle</mat-icon>
          <span>Activités fiduciaires</span>
          <span>Situé à Genève, CH</span>
          <span>Entre 3 et 7 employés</span>
          <span>Fondé entre 1980 et 1985</span>
          <a>Editer les informations</a>
        </div>
      </div>

      <div class="container content-container">
        <div *ngIf="valuationMode === 'intrinsic'" class="data-valuation">
          <h3>{{ t('intrinsic.title') }}</h3>
          <div class="data-container">
            <div class="data-value">{{ 300000.00 | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('intrinsic.assets') }}</div>
          </div>
          <div class="data-container">
            <div class="data-value">{{ 50000.00 | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('intrinsic.foreign-funds') }}</div>
          </div>
          <div class="data-container main-container">
            <div class="data-value">{{ 250000.00 | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('title') }}</div>
            <mat-icon (click)="infoIntrinsicClicked()">info</mat-icon>
          </div>
        </div>
        <div *ngIf="valuationMode === 'performance'" class="data-valuation">
          <h3>{{ t('performance.title') }}</h3>
          <div class="data-container">
            <div class="data-value">{{ (averageResult$ | async) | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('performance.results') }}</div>
          </div>
          <div class="data-container">
            <div class="data-value">{{ capitalisationRate | percent }}</div>
            <div class="data-subtitle">{{ t('performance.capitalization-rate') }}</div>
          </div>
          <div class="data-container main-container">
            <div class="data-value">{{ (valuationAverageResult$ | async) | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('title') }}</div>
            <mat-icon (click)="infoPerformanceClicked()">info</mat-icon>
          </div>
        </div>
        <div *ngIf="valuationMode === 'practitioners'" class="data-valuation">
          <h3>{{ t('practitioners.title') }}</h3>
          <div class="data-container">
            <div class="data-value">{{ 250000.00 | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('intrinsic.title') }}</div>
          </div>
          <div class="data-container">
            <div class="data-value">{{ 245000.00 | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('performance.title') }}</div>
          </div>
          <div class="data-container main-container">
            <div class="data-value">{{ 248000.00 | chf:2:true }}</div>
            <div class="data-subtitle">{{ t('title') }}</div>
            <mat-icon (click)="infoPractitionersClicked()">info</mat-icon>
          </div>
        </div>
        <div class="chart-eval">
          <img src="assets/chart-eval.jpg" alt="valuation for three years" />
          <div class="coming-soon">
            <span class="title">{{ t('beta.title') }}</span>
            <span>{{ t('beta.message') }}</span>
          </div>
        </div>
      </div>
    </main>
  </ng-container>
</ng-container>
<app-footer></app-footer>
