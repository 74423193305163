import { Component, Input } from '@angular/core';
import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { firstValueFrom } from 'rxjs';
import { getReferenceData } from '../../store/accounting.action';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { Store } from '@ngrx/store';
import { State } from '../../../core/reducers';
import { selectIsUserConnected } from '../../../authentication/store/authentication.selector';
import { selectComponentSubtitle } from '../../../core/router/router-store.selector';
import { AsyncPipe, NgIf } from '@angular/common';
import { TranslocoDirective } from '@ngneat/transloco';
import { selectCompanyInfo } from '../../store/accounting.selector';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    AsyncPipe,
    FaIconComponent,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    NgIf,
    TranslocoDirective,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {
  @Input() title: string = 'app.startup';
  @Input() beta = false;
  companyInfo$ = this.store.select(selectCompanyInfo);
  isUserConnected$ = this.store.select(selectIsUserConnected);
  subtitle$ = this.store.select(selectComponentSubtitle);

  protected readonly externalLink = faExternalLink;

  constructor(
    private authenticationService: AuthenticationService,
    private store: Store<State>,
  ) {
  }

  logoutClicked = async (): Promise<void> => {
    await firstValueFrom(this.authenticationService.signOut());
  }

  changeCompanyClicked = async (): Promise<void> => {
    this.authenticationService.signOut();
    await this.authenticationService.loginWithBexio();
    this.store.dispatch(getReferenceData());
  }
}
