import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectCompanyName } from "../../../dashboard/store/accounting.selector";
import { Store } from "@ngrx/store";
import { createCheckoutSession, paymentCancelled } from "../../store/profile.actions";
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit, OnDestroy {

  companyName$ = this.store.select(selectCompanyName);
  cancelSubscription : Subscription | undefined;
  monthly = true;

  constructor(
    private store: Store<any>,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.cancelSubscription = this.activatedRoute.queryParams.subscribe(params => {
      if (params['state'] === 'cancel') {
        this.store.dispatch(paymentCancelled());
      }
    });
  }

  ngOnDestroy(): void {
    this.cancelSubscription?.unsubscribe();
  }

  createCheckoutSessionClick = () => {
    this.store.dispatch(createCheckoutSession({ monthly: this.monthly }))
  };
}
