import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule } from '@ngneat/transloco';
import { HtmlNotificationComponent } from './html-notification/html-notification.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [
    HtmlNotificationComponent,
    LangSelectorComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatSelectModule,
    MatSnackBarModule,
    TranslocoModule,
  ],
  exports: [
    LangSelectorComponent
  ]
})
export class SharedModule { }
