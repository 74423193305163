import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss']
})
export class AmountInputComponent {

  @Input() amount: number = 0;
  @Output() userInput = new EventEmitter<MouseEvent | null>();
  @Output() amountChange = new EventEmitter<number>();

  constructor() { }

  amountChanged(event: any) {
    this.amountChange.emit(Number(event.target.value))
  }

  amountClicked(event: MouseEvent) {
    this.userInput.emit(event);
    if (event.target instanceof HTMLInputElement) {
      event.target.select()
    }
  }
}
