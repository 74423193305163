import { createAction } from '@ngrx/store';

export const showLoading = createAction(
  '[App] show loading'
);

export const hideLoading = createAction(
  '[App] hide loading'
);

export const clearLoading = createAction(
  '[App] clear loading'
);
