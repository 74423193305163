import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor(
  ) {
    if (environment.posthog.enabled) {
      posthog.init(environment.posthog.apiKey, { api_host: environment.posthog.apiHost });
    }
  }

  logEvent = (name: string, properties: any = {}) => {
    if (environment.posthog.enabled) {
      posthog.capture(name, properties);
    }
  }

}
