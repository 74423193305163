import { createAction, props } from '@ngrx/store';
import { User } from '../model/user.model';

export const getConnectedUserSuccess = createAction(
  '[Authentication] get connected user success',
  props<{ user: User }>()
);

export const getConnectedUserError = createAction(
  '[Authentication] get connected user error',
  props<{ error: Error }>()
);

export const userSignInWithBexioSuccess = createAction(
  '[Authentication] sign in with Bexio success',
  props<{ user: User }>()
);

export const userSignOutSuccess = createAction(
  '[Authentication] sign out success'
);
