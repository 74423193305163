import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from '../../component/info-dialog/info-dialog.component';
import valuationInfo from '../../model/valuation-info.constant';
import {
  selectAverageResultFromLastThreePreviousYears,
  selectValuationFromAverageResult
} from '../../store/accounting.selector';
import { ValuationMode } from '../../model/valuation-mode.model';
import { selectCashFlowGraphData } from '../../store/accounting.selector';
import { Color } from '../../model/color.model';
import { TranslocoService } from '@ngneat/transloco';
import { DashboardService } from '../../service/dashboard.service';
import { Observable } from 'rxjs';
import { AvailableLanguage } from "../../../transloco/available-lang";
import { selectComponentTitle } from '../../../core/router/router-store.selector';

@Component({
  selector: 'app-valuation',
  templateUrl: './valuation.component.html',
  styleUrls: ['./valuation.component.scss', '../common/common.component.scss']
})
export class ValuationComponent {
  title$ = this.store.select(selectComponentTitle);
  cashFlowGraphData$ = this.store.select(selectCashFlowGraphData);
  yearMonths$: Observable<string[]>;
  valuationMode: ValuationMode = ValuationMode.performance;
  valuationModeList: string[] = Object.values(ValuationMode);
  disabledColor: Color = new Color(17, 75, 122);
  enabledColor: Color = new Color(33, 150, 243);

  capitalisationRate = 0.08;
  averageResult$ = this.store.select(selectAverageResultFromLastThreePreviousYears);
  valuationAverageResult$ = this.store.select(selectValuationFromAverageResult(this.capitalisationRate));

  constructor(
    public dialog: MatDialog,
    private store: Store<any>,
    private dashboardService: DashboardService,
    private tranlocoService: TranslocoService,
  ) {
    this.yearMonths$ = dashboardService.getFormattedYearMonth();
  }

  infoIntrinsicClicked() {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: this.tranlocoService.translate('valuation.intrinsic.title'),
        content: valuationInfo.intrinsic[this.tranlocoService.getActiveLang() as AvailableLanguage]
      }
    });
  }

  infoPerformanceClicked() {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: this.tranlocoService.translate('valuation.performance.title'),
        content: valuationInfo.performance[this.tranlocoService.getActiveLang() as AvailableLanguage]
      }
    });
  }

  infoPractitionersClicked() {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: this.tranlocoService.translate('valuation.practitioners.title'),
        content: valuationInfo.practitioners[this.tranlocoService.getActiveLang() as AvailableLanguage]
      }
    });
  }
}
