import { State } from '../reducers';
import { createSelector } from '@ngrx/store';
import AppState from './app.reducer';

export const selectApp = (state: State) => state.appState;

export const selectLoading = createSelector(
  selectApp,
  (state: AppState) => state.loading > 0
);
