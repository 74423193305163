import { AccountCategory, AccountType } from './accounting.model';

export const ROW_TYPE = {
  heading: 'heading',
  node: 'node',
  recap: 'recap',
} as const;
export type RowType = typeof ROW_TYPE[keyof typeof ROW_TYPE];

export interface FlowTableRow {
  cat: AccountCategory;
  type: AccountType | null;
  name: string;
  accountNo: number;
  level: FlowTableLevel;
  opened: boolean;
  color: string | null;
  values: number[];
  total: number;
  children: FlowTableRow[];
  depth: number;
  rowType: RowType;
}

export interface MonthlyGraphData {
  input: number[];
  output: number[];
  result: number[];
  treasury: number[];
}

export enum FlowTableLevel {
  first = 'first-level',
  second = 'second-level',
  third = 'third-level',
  fourth = 'fourth-level',
}
