import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../component/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  requestConfirmation(
    title: string,
    message: string,
    accept = 'delete-action',
    cancel = 'cancel',
  ): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: { title, message, accept, cancel },
      autoFocus: false,
    });
    return firstValueFrom(dialogRef.afterClosed().pipe(map(value => !!value)));
  }

  openDialogComponent<T>(component: ComponentType<any>, data: any = {}, panelClass?: string) {
    const dialogRef = this.dialog.open(component, {
      panelClass,
      autoFocus: false,
      data,
    });
    return firstValueFrom(dialogRef.afterClosed() as Observable<T | undefined>);
  }

}
