import { NgModule } from '@angular/core';
import { AccountingComponent } from './page/accounting/accounting.component';
import { ScenarioSelectorComponent } from './component/scenario-selector/scenario-selector.component';
import { CategoryPipe, OpenedPipe } from './component/flow-table/pipe/flow-table.pipe';
import { ChartComponent } from './component/chart/chart.component';
import { ChfPipe } from './pipe/chf.pipe';
import { CommonModule } from '@angular/common';
import { FlowTableComponent } from './component/flow-table/flow-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MiniChartComponent } from './component/mini-chart/mini-chart.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PeriodSelectorComponent } from './component/period-selector/period-selector.component';
import { CashFlowComponent } from './page/cash-flow/cash-flow.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ValuationComponent } from './page/valuation/valuation.component';
import { InfoDialogComponent } from './component/info-dialog/info-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { ConfirmationDialogComponent } from './component/confirmation-dialog/confirmation-dialog.component';
import { RenameDialogComponent } from './component/rename-dialog/rename-dialog.component';
import { CreateScenarioDialogComponent } from './component/create-scenario-dialog/create-scenario-dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AmountInputComponent } from './component/amount-input/amount-input.component';
import { NgScrollbarModule } from "ngx-scrollbar";
import { RouterLink } from "@angular/router";
import { MatListModule } from "@angular/material/list";
import { RgbPipe } from './pipe/rgb.pipe';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';


@NgModule({
  declarations: [
    AccountingComponent,
    ScenarioSelectorComponent,
    CategoryPipe,
    CashFlowComponent,
    ChartComponent,
    ChfPipe,
    RgbPipe,
    FlowTableComponent,
    InfoDialogComponent,
    MiniChartComponent,
    OpenedPipe,
    PeriodSelectorComponent,
    ValuationComponent,
    ConfirmationDialogComponent,
    RenameDialogComponent,
    CreateScenarioDialogComponent,
    AmountInputComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatMenuModule,
    MatButtonModule,
    MatDividerModule,
    MatDialogModule,
    TranslocoModule,
    TranslocoLocaleModule,
    MatCheckboxModule,
    NgScrollbarModule,
    RouterLink,
    MatListModule,
    HeaderComponent,
    FooterComponent,
  ],
  providers: [
    ChfPipe,
    RgbPipe,
  ]
})
export class DashboardModule { }
