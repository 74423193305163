import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, of, tap } from 'rxjs';
import { AuthenticationService } from '../authentication.service';
import {
  getConnectedUserError,
  getConnectedUserSuccess,
  userSignInWithBexioSuccess,
  userSignOutSuccess,
} from './authentication.action';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { getReferenceData, getReferenceDataSuccess } from '../../dashboard/store/accounting.action';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationEffects {

  constructor(
    private actions$: Actions,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  getConnectedUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getReferenceDataSuccess),
      mergeMap(() => this.authenticationService.getCurrentUser().pipe(
        map(user => getConnectedUserSuccess({ user })),
        catchError(error => of(getConnectedUserError({ error })))
      ))
    );
  });

  userSignUpAndSignInSucceed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSignInWithBexioSuccess),
      map(() => {
        void this.router.navigateByUrl('/', { replaceUrl: true });
        return getReferenceData()
      })
    );
  });

  redirectToRootEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSignOutSuccess),
      tap(() => void this.router.navigateByUrl('/startup', { replaceUrl: true }))
    )
  }, { dispatch: false });

}
