import { AccountingComponent } from './dashboard/page/accounting/accounting.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StartupComponent } from './home/page/startup/startup.component';
import { DataProviderGuard } from './home/data-provider.guard';
import { CashFlowComponent } from './dashboard/page/cash-flow/cash-flow.component';
import { ValuationComponent } from './dashboard/page/valuation/valuation.component';
import { PricingComponent } from "./profile/page/pricing/pricing.component";

const routes: Routes = [
  { path: '', redirectTo: '/analysis', pathMatch: 'full' },
  {
    path: 'pricing',
    component: PricingComponent,
    data: {
      title: 'pricing.title'
    },
    canActivate: [ DataProviderGuard ]
  },
  {
    path: 'analysis',
    component: AccountingComponent,
    data: {
      title: 'financial-analysis.title'
    },
    canActivate: [ DataProviderGuard ]
  },
  {
    path: 'cash-flow',
    component: CashFlowComponent,
    data: {
      title: 'cash-flow.title'
    },
    canActivate: [ DataProviderGuard ]
  },
  {
    path: 'valuation',
    component: ValuationComponent,
    data: {
      title: 'valuation.title'
    },
    canActivate: [ DataProviderGuard ]
  },
  {
    path: 'startup',
    component: StartupComponent,
    data: {
      title: 'app.startup'
    }
  },
  {
    path: 'bexio',
    component: StartupComponent,
    data: {
      title: 'app.startup',
      referral: 'bexio'
    }
  },
  { path: '**', redirectTo: '/analysis', pathMatch: 'full' }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
