import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { filter, tap } from 'rxjs';
import { AnalyticsService } from '../service/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class LogActionsEffects {

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
  ) {
  }

  logAllActionsExceptApp$ = createEffect(() => {
    return this.actions$.pipe(
      filter(action => !action.type.includes('[App]')),
      tap(action => this.analyticsService.logEvent(action.type)),
    );
  }, { dispatch: false });

}
