import { State } from '../../core/reducers';
import { createSelector } from '@ngrx/store';
import {
  selectMonthlyScenarioStatesPerYearMonth
} from './scenario.reducer';
import { Dictionary } from '@ngrx/entity';
import {
  createRowNode,
  MonthlyAccounts
} from '../model/accounting.model';
import {
  selectYearMonthSpan
} from './span.selector';
import {
  selectAccountHierarchy,
  selectAccounts,
  selectOpenedAccounts
} from './accounting.selector';
import { BexioAccount } from '../model/bexio.model';
import { FlowTableRow, MonthlyGraphData, ROW_TYPE } from '../model/flow-table-row.model';

export const getScenarioState = (state: State) => state.scenarioState;

export const selectMonthlyScenarioAccounts = createSelector(
  getScenarioState,
  selectMonthlyScenarioStatesPerYearMonth
);

export const selectMonthlyScenarioAccountsWithinExistingSpan = createSelector(
  selectYearMonthSpan,
  selectMonthlyScenarioAccounts,
  (yearMonths: string[], monthlyStates: Dictionary<MonthlyAccounts>): MonthlyAccounts[] => {
    return yearMonths.reduce((accounts: MonthlyAccounts[], yearMonth) => {
      const monthlyAccounts = monthlyStates[yearMonth];
      if (monthlyAccounts) {
        accounts.push(monthlyAccounts);
      }
      return accounts;
    }, []);
  });

export const selectScenarioTableData = createSelector(
  selectYearMonthSpan,
  selectAccountHierarchy,
  selectMonthlyScenarioAccountsWithinExistingSpan,
  selectOpenedAccounts,
  (yearMonthSpan, rootNode, monthlyAccounts: MonthlyAccounts[], openedAccounts: number[]): FlowTableRow[] | undefined => {
    if (rootNode && monthlyAccounts.length === yearMonthSpan.length) {
      const rootNodes = createRowNode(rootNode, 0, monthlyAccounts, openedAccounts).children;
      return [
        rootNodes[0], {...rootNodes[0], children: [], rowType: ROW_TYPE.recap},
      ];
    } else {
      return undefined;
    }
  }
);

export const selectScenarioInputData = createSelector(
  selectScenarioTableData,
  (tableData: FlowTableRow[] | undefined): number[] => {
    if (tableData && tableData.length) {
      const length = tableData[0].values.length
      const inputRows = [
        tableData[0].children[0].values,
        // tableData[1].values // TODO properly handle these values
      ];
      return inputRows.reduce((cur, acc) => {
          return cur.map((value, index) => acc[index] + value)
        }
        , Array.from({length}).fill(0) as number[]);
    }
    return [];
  }
);

export const selectScenarioOutputData = createSelector(
  selectScenarioTableData,
  (tableData: FlowTableRow[] | undefined): number[] => {
    if (tableData && tableData.length) {
      const length = tableData[0].values.length
      const inputRows = [
        tableData[0].children[1].values,
        tableData[0].children[2].values,
        tableData[0].children[3].values,
        // tableData[0].children[4].values, // TODO properly handle these values
        // tableData[0].children[5].values, // TODO properly handle these values
        // tableData[2].values // TODO properly handle these values
      ];
      return inputRows.reduce((cur, acc) => {
          return cur.map((value, index) => acc[index] + value)
        }
        , Array.from({length}).fill(0) as number[]);
    }
    return [];
  }
);

export const selectScenarioTreasuryData = createSelector(
  selectScenarioTableData,
  (tableData: FlowTableRow[] | undefined): FlowTableRow | undefined => {
    if (tableData && tableData.length) {
      return tableData[tableData.length - 1];
    }
    return undefined;
  }
);

export const selectScenarioGraphData = createSelector(
  selectScenarioInputData,
  selectScenarioOutputData,
  selectScenarioTreasuryData,
  (cashIn, cashOut, treasuryRows) => {
    const result = cashIn.map((value, index) => value - cashOut[index]);
    const treasury = treasuryRows?.values ?? [];
    return {
      input: cashIn,
      output: cashOut,
      result,
      treasury
    } as MonthlyGraphData;
  }
);

export const selectScenarioData = createSelector(
  selectAccounts,
  selectYearMonthSpan,
  selectMonthlyScenarioAccounts,
  (accounts: BexioAccount[], yearMonths: string[], monthlyAccounts: Dictionary<MonthlyAccounts>): number[][] => {
    return accounts.map((account, index) =>
      yearMonths.map(yearMonth => monthlyAccounts[yearMonth]?.accountsValues[index].value || 0 )
    );
  });

export const selectScenarios = createSelector(
  getScenarioState,
  (state) => state.scenarios
);

export const selectCurrentScenarioId = createSelector(
  getScenarioState,
  (state) => state.currentScenarioId
);
