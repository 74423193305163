import { State } from '../../core/reducers';
import { createSelector } from '@ngrx/store';
import AuthenticationState from './authentication.reducer';
import { User } from '../model/user.model';


const FREE_TRY_DAYS_LENGTH = 15;

export const selectAuthentication = (state: State) => state.authenticationState;

export const selectConnectedUser = createSelector(
  selectAuthentication,
  (state: AuthenticationState) => state.user
);

export const selectIsUserConnected = createSelector(
  selectConnectedUser,
  (user?: User) => !!user
);

export const selectRemainingDays = createSelector(
  selectConnectedUser,
  (user?: User) => user?.createdAt ? Math.round((user?.createdAt.getTime() + FREE_TRY_DAYS_LENGTH * 24 * 60 * 60 * 1000 - Date.now()) / (24 * 60 * 60 * 1000)) : 0
);
