import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthorizationInterceptor } from './home/bexio-authorization.interceptor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AccountingEffects } from './dashboard/store/accounting.effects';
import { metaReducers, reducers } from './core/reducers';
import { environment } from '../environments/environment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as Sentry from "@sentry/angular-ivy";
import localeDe from '@angular/common/locales/de-CH';
import localeFr from '@angular/common/locales/fr-CH';
import localeIt from '@angular/common/locales/it-CH';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AuthenticationModule } from './authentication/authentication.module';
import { AuthenticationEffects } from './authentication/store/authentication.effects';
import { AngularFireModule } from '@angular/fire/compat';
import { MonthlyEffects } from './dashboard/store/monthly.effects';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { ScenarioEffects } from './dashboard/store/scenario.effects';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { MatIconModule } from '@angular/material/icon';
import { DashboardModule } from './dashboard/dashboard.module';
import { MatButtonModule } from '@angular/material/button';
import { HomeModule } from './home/home.module';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouteSerializer } from './core/router/route-serializer';
import { MatMenuModule } from '@angular/material/menu';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { LoadingComponent } from './core/loading/loading.component';
import { SharedModule } from './shared/shared.module';
import { ErrorReportingEffects } from './shared/store/error-reporting.effects';
import { RestApiErrorInterceptor } from './shared/utils/rest-api-error.interceptor';
import { NgScrollbarModule } from "ngx-scrollbar";
import { ProfileModule } from "./profile/profile.module";
import { ProfileEffects } from "./profile/store/profile.effects";
import { AppEffects } from "./core/store/app.effects";
import { MenuComponent } from './dashboard/component/menu/menu.component';
import { LogActionsEffects } from './shared/store/log-actions-effects.service';

registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeIt);

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM YYYY',
  },
  display: {
    dateInput: 'MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MMMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

Sentry.init({
  dsn: environment.sentryDsn,
  release: environment.version,
  ignoreErrors: [
    'Non-Error exception captured',
    'ResizeObserver loop completed with undelivered notifications',
  ]
});

@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    AppRoutingModule,
    AuthenticationModule,
    BrowserModule,
    BrowserAnimationsModule,
    DashboardModule,
    HomeModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production, connectInZone: true }),
    EffectsModule.forRoot([
      AppEffects,
      AccountingEffects,
      AuthenticationEffects,
      ScenarioEffects,
      MonthlyEffects,
      ErrorReportingEffects,
      ProfileEffects,
      LogActionsEffects,
    ]),
    StoreRouterConnectingModule.forRoot({
      serializer: RouteSerializer,
    }),
    FontAwesomeModule,
    MatButtonModule,
    MatDatepickerModule,
    MatIconModule,
    MatMenuModule,
    MatProgressBarModule,
    NgScrollbarModule,
    ProfileModule,
    SharedModule,
    TranslocoRootModule,
    MenuComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-CH' },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RestApiErrorInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: REGION, useValue: 'europe-west6' },
    // { provide: USE_EMULATOR, useValue: ['localhost', 5001] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
