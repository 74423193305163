import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './create-scenario-dialog.component.html',
  styleUrls: [
    '../common-dialog/common-dialog.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateScenarioDialogComponent {

  name: string;
  initDataWithPreviousYear: boolean = false

  constructor(
    public dialogRef: MatDialogRef<CreateScenarioDialogComponent, ({name: string, initDataWithPreviousYear: boolean})>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {
    this.name = data.name;
  }

  validate() {
    this.dialogRef.close({
      name: this.name.trim(),
      initDataWithPreviousYear: this.initDataWithPreviousYear
    });
  }

  cancel() {
    this.dialogRef.close();
  }

}
