import { createReducer, on } from '@ngrx/store';
import { changeEndYearMonth, changeStartYearMonth } from './span.action';
import { getReferenceDataSuccess } from './accounting.action';
import { BexioBusinessYear } from '../model/bexio.model';

export default interface SpanState {
  businessYears: BexioBusinessYear[];
  startYear: number;
  startMonth: number;
  endMonth: number;
  endYear?: number;
}

export const initialState: SpanState = {
  businessYears: [],
  startYear: new Date().getFullYear(),
  startMonth: 0,
  endMonth: 11,
  endYear: undefined,
};

export const reducer = createReducer(
  initialState,
  on(getReferenceDataSuccess, (state, action): SpanState => {
    const currentDateTime = new Date().getTime();

    const orderedBusinessYears = action.businessYears.slice().sort((a, b) => a.start.localeCompare(b.start));
    const currentBusinessYear = orderedBusinessYears.find(businessYears =>
      currentDateTime >= new Date(businessYears.start).getTime() && currentDateTime <= new Date(businessYears.end).getTime()
    ) ?? action.businessYears.slice(-1)[0]
    const startDate = new Date(currentBusinessYear.start);
    const endDate = new Date(currentBusinessYear.end);
    return {
      ...state,
      businessYears: orderedBusinessYears,
      startMonth: startDate.getMonth(),
      startYear: startDate.getFullYear(),
      endMonth: endDate.getMonth(),
      endYear: endDate.getFullYear()
    }
  }),
  on(changeStartYearMonth, (state, action): SpanState => {
    const startDate = new Date(action.year, action.month);
    let endDate = new Date(state.endYear ?? state.startYear, state.endMonth);
    if (startDate.getTime() >= endDate.getTime()) {
      endDate = new Date(startDate.getTime());
      endDate.setMonth(endDate.getMonth() + 11);
    }
    return {
      ...state,
      startMonth: action.month,
      startYear: action.year,
      endMonth: endDate.getMonth(),
      endYear: endDate.getFullYear()
    }
  }),
  on(changeEndYearMonth, (state, action): SpanState => {
    let startDate = new Date(state.startYear, state.startMonth);
    const endDate = new Date(action.year, action.month);
    if (startDate.getTime() >= endDate.getTime()) {
      startDate = new Date(endDate.getTime());
      startDate.setMonth(startDate.getMonth() - 11);
    }
    return {
      ...state,
      startMonth: startDate.getMonth(),
      startYear: startDate.getFullYear(),
      endMonth: action.month,
      endYear: action.year
    }
  }),
);
