import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { getReferenceData } from './dashboard/store/accounting.action';
import { State } from './core/reducers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    private store: Store<State>,
  ) {
  }

  ngOnInit() {
    this.store.dispatch(getReferenceData());
  }

}
