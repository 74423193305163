import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, forkJoin, of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  getAccountHierarchySuccess,
  getReferenceData,
  getReferenceDataError,
  getReferenceDataSuccess,
  getTreasuryEntriesForCurrentBusinessYear,
  getTreasuryEntriesForCurrentBusinessYearError,
  getTreasuryEntriesForCurrentBusinessYearSuccess,
  setInitialTreasury,
} from './accounting.action';
import { BexioService } from '../service/bexio.service';
import { Store } from '@ngrx/store';
import { AccountingService } from '../service/accounting.service';
import { Router } from '@angular/router';
import {
  selectCompanyInfo,
  selectCurrencyMap,
  selectInitialTreasuryAccountId,
  selectTreasuryAccountIds,
} from './accounting.selector';
import { changeStartYearMonth } from './span.action';
import { selectFirstDayOfCurrentBusinessYear } from './span.selector';
import { LocalStorageService } from '../../home/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AccountingEffects {

  constructor(
    private actions$: Actions,
    private bexioService: BexioService,
    private accountingService: AccountingService,
    private localStorageService: LocalStorageService,
    private store: Store<any>,
    private router: Router,
  ) {
  }

  getReferenceDataEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getReferenceData),
      concatLatestFrom(() => [this.store.select(selectCompanyInfo)]),
      filter(([, companyData]) => !companyData),
      switchMap(() =>
        forkJoin([
          this.bexioService.getCompanyInfo(),
          this.bexioService.getCurrencyExchangeFactors(),
          this.bexioService.getAccounts(),
          this.bexioService.getAccountGroups(),
          this.bexioService.getBusinessYears(),
        ]).pipe(
          map(([company, currencies, accounts, accountGroups, businessYears]) =>
            getReferenceDataSuccess({ company, currencies, accounts, accountGroups, businessYears })
          ),
          catchError((error) => {
            if (this.localStorageService.hasIdfInfo()) {
              void this.router.navigateByUrl('/startup', { replaceUrl: true });
              this.localStorageService.destroyIdpInfo();
            }
            return of(getReferenceDataError({ error }))
          })
        )
      )
    );
  });

  processAccountsAndGroupsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getReferenceDataSuccess),
      map(({ accounts, accountGroups }) => getAccountHierarchySuccess({
        root: this.accountingService.buildAccountHierarchy(accountGroups, accounts)
      }))
    );
  });

  refreshTreasuryEntriesAfterAccountDataEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changeStartYearMonth, getAccountHierarchySuccess),
      map(() => getTreasuryEntriesForCurrentBusinessYear())
    );
  });

  getTreasuryEntriesForCurrentBusinessYearEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTreasuryEntriesForCurrentBusinessYear),
      concatLatestFrom(() => [
        this.store.select(selectFirstDayOfCurrentBusinessYear),
        this.store.select(selectTreasuryAccountIds),
        this.store.select(selectInitialTreasuryAccountId),
      ]),
      switchMap(([, day, treasuryAccountIds, initialTreasuryAccountId]) => {
        if (day) {
          return this.bexioService.getDailyEntriesBetweenAccounts(day, treasuryAccountIds, initialTreasuryAccountId).pipe(
            map(entries => getTreasuryEntriesForCurrentBusinessYearSuccess({ entries })),
            catchError((error) => of(getTreasuryEntriesForCurrentBusinessYearError({ error })))
          );
        }
        return of(getTreasuryEntriesForCurrentBusinessYearSuccess({ entries: [] }));
      })
    );
  });

  calculateInitialTreasuryEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTreasuryEntriesForCurrentBusinessYearSuccess),
      concatLatestFrom(() => [
        this.store.select(selectInitialTreasuryAccountId),
        this.store.select(selectCurrencyMap)
      ]),
      map(([{ entries }, treasuryAccountId, rates]) => {
        const initialTreasury = entries.reduce((treasury, entry) => {
          const rate = rates.get(entry.currency_id) ?? 1;
          const amount = rate * entry.amount;
          if (entry.credit_account_id === treasuryAccountId) {
            return treasury + amount;
          }
          return treasury - amount;
        }, 0);
        return setInitialTreasury({ initialTreasury })
      })
    );
  });

}
