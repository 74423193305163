import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: [
    '../common-dialog/common-dialog.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RenameDialogComponent {

  name: string;

  constructor(
    public dialogRef: MatDialogRef<RenameDialogComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string }
  ) {
    this.name = data.name;
  }

  validate() {
    this.dialogRef.close(this.name.trim());
  }

  cancel() {
    this.dialogRef.close();
  }

}
