import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class UserReportService {

  supportUrls = [
    {
      language: 'en',
      id: '6a45d64b-c839-45d9-a61d-bffc3858694d',
    },
    {
      language: 'de',
      id: '80c8dbf9-76fa-4181-bd93-5b3bfb0b5824',
    },
    {
      language: 'fr',
      id: '0be542e5-b947-45db-a92f-c37b35d283b3',
    },
    {
      language: 'it',
      id: '02777595-fd09-4e5f-a0db-b1c4bc826687',
    },
  ];

  constructor(private meta: Meta) {
  }

  getSupportInfo(locale: string) {
    return this.supportUrls
        .find(support => locale.startsWith(support.language)) || this.supportUrls[0];
  }

  getSupportUrl(locale: string): string {
    return `https://feedback.userreport.com/${this.getSupportInfo(locale).id}/`;
  }
}
