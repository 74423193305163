import {
  BexioAccount,
  BexioAccountGroup,
  BexioBusinessYear,
  BexioCompanyInfo,
  BexioCurrency,
  BexioCurrencyExchangeRate,
  BexioEntry,
  CurrencyExchangeFactor,
  ParsedBexioEntry,
  YearMonthEntries
} from '../model/bexio.model';
import { mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, map, Observable, of } from 'rxjs';
import { buildYearMonthNumber, YearMonth } from '../model/period.model';
import { environment } from '../../../environments/environment';
import { formatDate } from '@angular/common';

export const BEXIO_API_PROXY_URL = `${environment.api_url}/bexioProxy`;

@Injectable({
  providedIn: 'root'
})
export class BexioService {

  constructor(private readonly http: HttpClient) {
  }

  getCompanyInfo = (): Observable<BexioCompanyInfo> => {
    const params = new HttpParams().set('path', '/2.0/company_profile');
    return this.http.get<any[]>(BEXIO_API_PROXY_URL, { params }).pipe(
      map(profiles => ({
        id: profiles[0].id,
        name: profiles[0].name,
        tvaId: profiles[0].ust_id_nr ? profiles[0].ust_id_nr : profiles[0].mwst_nr,
      }))
    );
  };

  getCurrencyExchangeFactors = (): Observable<CurrencyExchangeFactor[]> =>
    this.getCurrencies().pipe(
      map(currencies => currencies.filter(currency => currency.name !== 'CHF').map(currency => this.getCurrencyExchangeRate(currency.id).pipe(
        map(currencyExchangeRates => ({
          currencyId: currency.id,
          name: currency.name,
          factor: currencyExchangeRates?.factor_nr
        } as CurrencyExchangeFactor))
      ))),
      switchMap(currencyExchangeRates$ => forkJoin([...currencyExchangeRates$])),
    );

  getCurrencies = (): Observable<BexioCurrency[]> => {
    const params = new HttpParams().set('path', '/3.0/currencies');
    return this.http.get<BexioCurrency[]>(BEXIO_API_PROXY_URL, { params });
  };

  getCurrencyExchangeRate = (currencyId: number): Observable<BexioCurrencyExchangeRate | null> => {
    const params = new HttpParams().set('path', `/3.0/currencies/${currencyId}/exchange_rates`);
    return this.http.get<BexioCurrencyExchangeRate[]>(BEXIO_API_PROXY_URL, { params }).pipe(
      map(currencyExchangeRates => currencyExchangeRates.length ? currencyExchangeRates[0] : null)
    )
  }

  getAccounts = () => {
    const params = new HttpParams().set('path', '/2.0/accounts');
    return this.http.get<BexioAccount[]>(BEXIO_API_PROXY_URL, { params });
  };

  getAccountGroups = () => {
    const params = new HttpParams().set('path', '/2.0/account_groups');
    return this.http.get<BexioAccountGroup[]>(BEXIO_API_PROXY_URL, { params });
  }

  getBusinessYears = (): Observable<BexioBusinessYear[]> => {
    const params = new HttpParams().set('path', '/3.0/accounting/business_years');
    return this.http.get<BexioBusinessYear[]>(BEXIO_API_PROXY_URL, { params });
  }

  getYearMonthEntries = (yearMonths: YearMonth[]): Observable<YearMonthEntries[]> =>
    forkJoin(yearMonths.map(yearMonth => this.getMonthlyEntries(yearMonth)));

  getMonthlyEntries(yearMonth: YearMonth): Observable<YearMonthEntries> {
    const limit = 2000;
    const params = this.getQueryParamsForJournal(yearMonth.year, yearMonth.month, limit);

    return this.http.get<BexioEntry[]>(BEXIO_API_PROXY_URL, { params, observe: 'response' }).pipe(
      mergeMap(response => {
        const totalCount = Number(response.headers.get('x-total-count'));
        const remainingCount = Math.floor(totalCount / limit);
        const result = this.parseEntries(response.body ?? []);

        if (remainingCount === 0) {
          return of(result);
        }

        const offsets = Array.from({ length: remainingCount }, (_, i) => (i + 1) * limit);
        const subsequentRequests = offsets.map((offset) => {
          const stepParams = this.getQueryParamsForJournal(yearMonth.year, yearMonth.month, limit, offset);
          return this.http.get<BexioEntry[]>(BEXIO_API_PROXY_URL, {
            params: stepParams,
          }).pipe(map(entries => this.parseEntries(entries)));
        });

        return forkJoin(subsequentRequests).pipe(
          map((entries) => result.concat(entries.flat()))
        );
      })
    ).pipe(map(entries => ({
      yearMonth,
      entries
    })));
  }

  getDailyEntriesBetweenAccounts = (day: Date, accountNumbers: number[], otherAccountNumber: number = 0): Observable<BexioEntry[]> =>
    this.getDailyEntries(day).pipe(map(entries => entries.filter(entry =>
      accountNumbers.includes(entry.credit_account_id) && otherAccountNumber === entry.debit_account_id ||
      accountNumbers.includes(entry.debit_account_id) && otherAccountNumber === entry.credit_account_id
    )));


  getDailyEntries = (day: Date): Observable<BexioEntry[]> => {
    const dayString = formatDate(day, 'yyyy-MM-dd', 'fr-CH');
    const params = new HttpParams()
      .set('path', '/3.0/accounting/journal')
      .set('from', dayString)
      .set('to', dayString)
      .set('limit', 2000)
      .set('offset', 0);
    return this.http.get<BexioEntry[]>(BEXIO_API_PROXY_URL, { params });
  }

  private getQueryParamsForJournal(year: number, month: number, limit: number = 100, offset: number = 0): HttpParams {
    const date = new Date(year, month);
    const bounds = this.getMonthBounds(date);
    return new HttpParams()
      .set('path', '/3.0/accounting/journal')
      .set('from', bounds.from)
      .set('to', bounds.to)
      .set('limit', limit)
      .set('offset', offset);
  }

  private parseEntries = (entries: BexioEntry[]): ParsedBexioEntry[] => entries.map(x => {
    const theDate = new Date(x.date);
    const year = theDate.getFullYear();
    const month = theDate.getMonth();
    return ({ ...x, month, yearAndMonth: buildYearMonthNumber(year, month) });
  });

  private getMonthBounds = (date: Date): { from: string, to: string } => {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const year = lastDayOfMonth.getFullYear();
    const month = `${lastDayOfMonth.getMonth() + 1}`.padStart(2, '0');
    return { from: `${year}-${month}-01`, to: `${year}-${month}-${lastDayOfMonth.getDate()}` };
  }

}
