import { Component } from '@angular/core';
import {
  selectCashFlowGraphData,
  selectInitialTreasury,
  selectInitialTreasuryValid,
} from '../../store/accounting.selector';
import { changeEndYearMonth, changeStartYearMonth } from '../../store/span.action';
import { Color } from '../../model/color.model';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { YearMonth } from '../../model/period.model';
import { selectEndYearMonth, selectStartYearMonth } from '../../store/span.selector';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { DashboardService } from '../../service/dashboard.service';
import { selectLoading } from '../../../core/store/app.selector';
import { selectComponentTitle } from '../../../core/router/router-store.selector';

@Component({
  selector: 'app-cash-flow',
  templateUrl: './cash-flow.component.html',
  styleUrls: ['./cash-flow.component.scss', '../common/common.component.scss']
})
export class CashFlowComponent {
  title$ = this.store.select(selectComponentTitle);
  loading$ = this.store.select(selectLoading);
  startYearMonth$ = this.store.select(selectStartYearMonth);
  endYearMonth$ = this.store.select(selectEndYearMonth);
  initialTreasury$ = this.store.select(selectInitialTreasury);
  initialTreasuryValid$ = this.store.select(selectInitialTreasuryValid);
  cashFlowGraphData$ = this.store.select(selectCashFlowGraphData);
  locale$ = this.translocoLocaleService.localeChanges$;
  yearMonths$: Observable<string[]>;
  inputColor: Color = new Color(76, 175, 80);
  outputColor: Color = new Color(244, 67, 54);
  variationColor: Color = new Color(33, 150, 243);

  constructor(
    private store: Store<any>,
    private dashboardService: DashboardService,
    private translocoLocaleService: TranslocoLocaleService,
  ) {
    this.yearMonths$ = dashboardService.getFormattedYearMonth();
  }

  changeStartYearMonth = (startYearMonth: YearMonth) =>
    this.store.dispatch(changeStartYearMonth({ year: startYearMonth.year, month: startYearMonth.month }));

  changeEndYearMonth = (endYearMonth: YearMonth) =>
    this.store.dispatch(changeEndYearMonth({ year: endYearMonth.year, month: endYearMonth.month }));
}
