import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './services/local-storage.service';
import { Observable } from "rxjs";
import { AnalyticsService } from '../shared/service/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class DataProviderGuard  {
  constructor(
    private analyticsService: AnalyticsService,
    private localStorageService: LocalStorageService,
    private router: Router,
  ) {}

  canActivate(): boolean | Observable<boolean> {
    if (!this.localStorageService.hasIdfInfo()) {
      this.analyticsService.logEvent('user_not_connected');
      void this.router.navigate(['/startup'], { replaceUrl: true });
      return false;
    }
    return true;
  }
}
