<div *transloco="let t; read: 'period'">
    <button mat-stroked-button [matMenuTriggerFor]="animals">
      {{ rawStartYearMonth | date:'MMM YYYY' : undefined : (locale$ | async) ?? undefined | titlecase }}
        –
      {{ rawEndYearMonth | date:'MMM YYYY' : undefined : (locale$ | async) ?? undefined | titlecase }}
    </button>
    <mat-menu #animals="matMenu" xPosition="before">
      <div class="menu-item" mat-menu-item>
        <mat-form-field appearance="outline" class="small-width" subscriptSizing="dynamic">
          <mat-label>{{ t('start-date') }}</mat-label>
          <input matInput
                 [matDatepicker]="startDatePicker"
                 [formControl]="startDateControl"
                 (click)="startDatePicker.open(); $event.stopPropagation();" readonly>
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year"
                          (monthSelected)="setStartDate($event, startDatePicker)"
                          #startDatePicker>
          </mat-datepicker>
        </mat-form-field>
      </div>
      <div class="menu-item" mat-menu-item>
        <mat-form-field appearance="outline" class="small-width" subscriptSizing="dynamic">
          <mat-label>{{ t('end-date') }}</mat-label>
          <input matInput
                 [matDatepicker]="endDatePicker"
                 [formControl]="endDateControl"
                 (click)="endDatePicker.open(); $event.stopPropagation();" readonly>
          <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
          <mat-datepicker startView="multi-year"
                          (monthSelected)="setEndDate($event, endDatePicker)"
                          #endDatePicker>
          </mat-datepicker>
        </mat-form-field>
      </div>
    </mat-menu>
</div>
