import { Pipe, PipeTransform } from '@angular/core';
import { CategoryModel } from '../model/category.model';
import { FlowTableLevel, FlowTableRow } from '../../../model/flow-table-row.model';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  transform(categories: CategoryModel[], type: string): CategoryModel[] {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    return categories.filter(category => category.type === type);
  }
}

@Pipe({
  name: 'opened'
})
export class OpenedPipe implements PipeTransform {

  transform(flowTableRow: FlowTableRow, flowTableRows: FlowTableRow[], initialIndex: number): boolean {
    if (flowTableRow.level === FlowTableLevel.first) {
      return true;
    }
    let fourthLevelOpened = false;
    if (flowTableRow.level === FlowTableLevel.fourth) {
      let index = initialIndex - 1;
      while (flowTableRows[index].level === FlowTableLevel.fourth && index > 0) {
        index--;
      }
      fourthLevelOpened = flowTableRows[index].opened;
    }
    let thirdLevelOpened = false;
    if (flowTableRow.level === FlowTableLevel.third || fourthLevelOpened) {
      let index = initialIndex - 1;
      while (flowTableRows[index].level !== FlowTableLevel.second && index > 0) {
        index--;
      }
      thirdLevelOpened = flowTableRows[index].opened;
    }
    if (flowTableRow.level === FlowTableLevel.second || thirdLevelOpened) {
      let index = initialIndex - 1;
      while (flowTableRows[index].level !== FlowTableLevel.first && index > 0) {
        index--;
      }
      return flowTableRows[index].opened;
    }
    return false;
  }
}
