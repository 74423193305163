import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  template: `
    <div class="content" *transloco="let t; read: 'dialog.budget'">
      <h2>{{ data.title }}</h2>
      <div class="info">
        {{ data.message }}
      </div>
      <mat-dialog-actions align="end">
        <button mat-flat-button color="warn" class="mat-button-base" [mat-dialog-close]="true"><span>{{ t(data.accept) }}</span></button>
        <button mat-button class="mat-button-base" [mat-dialog-close]="false"><span>{{ t(data.cancel) }}</span></button>
      </mat-dialog-actions>
    </div>
  `,
  styleUrls: [
    '../common-dialog/common-dialog.component.scss'
  ]
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string; accept: string; cancel: string }
  ) {}
}
