import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StartupComponent } from './page/startup/startup.component';
import { SharedModule } from '../shared/shared.module';
import { TranslocoModule } from '@ngneat/transloco';
import { HeaderComponent } from '../dashboard/component/header/header.component';
import { FooterComponent } from '../dashboard/component/footer/footer.component';

@NgModule({
  declarations: [
    StartupComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    SharedModule,
    TranslocoModule,
    HeaderComponent,
    FooterComponent,
  ],
  exports: [],
  providers: [],
})
export class HomeModule { }
