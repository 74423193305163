import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { processedMonthlyEntriesIntoAccountStates } from './monthly-accounts.action';
import {
  createScenarioSuccess,
  createScenarioWithPreviousYearSuccess,
  deleteScenarioSuccess,
  duplicateScenarioSuccess,
  getScenariosSuccess,
  getScenarioValuesSuccess,
  setScenarioNameSuccess,
  setScenarioValueSuccess,
  setScenarioValuesSuccess
} from './scenario.action';
import { MonthlyAccounts } from '../model/accounting.model';
import { Scenario } from './scenario.model';

export default interface ScenarioState extends EntityState<MonthlyAccounts> {
  scenarios: Scenario[];
  currentScenarioId: string | undefined;
}

export function selectId(a: MonthlyAccounts): string {
  return a.yearMonth;
}

export function sortComparer(a: MonthlyAccounts, b: MonthlyAccounts): number {
  return a.yearMonth.localeCompare(b.yearMonth);
}

export const adapter = createEntityAdapter<MonthlyAccounts>({
  selectId,
  sortComparer,
});

export const initialState: ScenarioState = adapter.getInitialState({
  scenarios: [],
  currentScenarioId: undefined,
});

export const reducer = createReducer(
  initialState,
  on(getScenariosSuccess, (state, { scenarios }): ScenarioState => {
    if (scenarios.length > 0) {
      const orderedScenarios = scenarios.slice().sort((a, b) => a.name.localeCompare(b.name));
      return { ...state, scenarios: orderedScenarios, currentScenarioId: orderedScenarios[0].id };
    }
    return { ...state, scenarios, currentScenarioId: undefined };
    }
  ),
  on(processedMonthlyEntriesIntoAccountStates, (state, { monthlyAccountsList }): ScenarioState => {
    return monthlyAccountsList.reduce((stateAcc: ScenarioState, monthlyAccounts) => {
      const yearMonth = monthlyAccounts.yearMonth;
      const scenarioMonthlyAccounts = state.entities[yearMonth];
      const existingBudgetAccountValues = scenarioMonthlyAccounts?.accountsValues;
      const accountsValues = monthlyAccounts.accountsValues.map(accountValue => {
        if (existingBudgetAccountValues) {
          const value = existingBudgetAccountValues?.find(account => account.account_no === accountValue.account_no)?.value ?? 0;
          return { ...accountValue, value };
        } else {
          return { ...accountValue, value: 0 };
        }
      });
      const existingValuesToKeep = [];
      if (existingBudgetAccountValues) {
        const processedAccounts = accountsValues.map(accountsValue => accountsValue.account_no);
        for (const existingAccount of existingBudgetAccountValues) {
          if (!processedAccounts.includes(existingAccount.account_no)) {
            existingValuesToKeep.push(existingAccount);
          }
        }
      }
      const updatedAccountsValues = [...accountsValues, ...existingValuesToKeep].sort((a, b) => {
        return a.account_no.localeCompare(b.account_no);
      });
      return adapter.upsertOne({ yearMonth, accountsValues: updatedAccountsValues }, stateAcc);
    }, state);
  }),
  on(setScenarioValueSuccess, (state, { scenarioId, monthlyAccount }): ScenarioState => {
    const updatedState = { ...state, currentScenarioId: scenarioId };
    const existingAccountsValues = updatedState.entities[monthlyAccount.yearMonth]?.accountsValues;
    if (existingAccountsValues) {
      const accountsValues = existingAccountsValues.map(existingAccountValue =>
        existingAccountValue.account_no === monthlyAccount.accountValue.account_no ? monthlyAccount.accountValue : existingAccountValue
      )
      return adapter.upsertOne({ yearMonth: monthlyAccount.yearMonth, accountsValues }, updatedState)
    }
    return updatedState;
  }),
  on(setScenarioValuesSuccess, (state, { scenarioId, monthlyAccountList }): ScenarioState => {
    const updatedState = { ...state, currentScenarioId: scenarioId };
    return monthlyAccountList.reduce((stateAcc: ScenarioState, monthlyAccount) => {
      const existingAccountsValues = stateAcc.entities[monthlyAccount.yearMonth]?.accountsValues;
      if (existingAccountsValues) {
        const accountsValues = existingAccountsValues.map(existingAccountValue =>
          existingAccountValue.account_no === monthlyAccount.accountValue.account_no ? monthlyAccount.accountValue : existingAccountValue
        )
        return adapter.upsertOne({ yearMonth: monthlyAccount.yearMonth, accountsValues }, stateAcc)
      }
      return stateAcc;
    }, updatedState);
  }),
  on(getScenarioValuesSuccess, (state, { scenarioId, values }): ScenarioState => {
    const updatedState = { ...state, currentScenarioId: scenarioId };
    const yearMonths: string[] = Object.keys(values).filter(key => key !== 'name');
    return yearMonths.reduce((stateAcc: ScenarioState, yearMonth) => {
      const newAccountValues = values[yearMonth];
      const newAccounts: string[] = Object.keys(newAccountValues);
      const existingYearMonth = stateAcc.entities[yearMonth];
      if (existingYearMonth) {
        const accountsValues = existingYearMonth.accountsValues.map(accountValue => {
          if (newAccounts.includes(accountValue.account_no)) {
            return {
              ...accountValue,
              value: newAccountValues[accountValue.account_no]
            };
          }
          return {
            ...accountValue,
            value: 0
          };
        });
        return adapter.upsertOne({ yearMonth, accountsValues }, stateAcc);
      } else {
        const accountsValues = newAccounts.map(newAccount => {
          const value = Number(newAccountValues[newAccount]);
          return { account_no: newAccount, value };
        });
        return adapter.upsertOne({ yearMonth, accountsValues }, stateAcc);
      }
    }, clearValues(state.ids as string[], updatedState));
  }),
  on(setScenarioNameSuccess, (state, { scenarioId, newName }): ScenarioState => {
    const updatedScenarios = state.scenarios.map(scenario => {
      if (scenario.id !== scenarioId) {
        return scenario;
      } else {
        return { ...scenario, name: newName };
      }
    }).sort((a, b) => a.name.localeCompare(b.name));
    return { ...state, scenarios: updatedScenarios };
  }),
  on(deleteScenarioSuccess, (state, { scenarioId }): ScenarioState => {
    const updatedScenarios = state.scenarios.filter(scenario => scenario.id !== scenarioId);
    const updatedState = {
      ...state,
      scenarios: updatedScenarios,
      currentScenarioId: updatedScenarios.length ? updatedScenarios[0].id : undefined
    };
    return clearValues(state.ids as string[], updatedState);
  }),
  on(createScenarioSuccess, duplicateScenarioSuccess, createScenarioWithPreviousYearSuccess, (state, { scenario }): ScenarioState => {
    const updatedState = {
      ...state,
      scenarios: [...state.scenarios, scenario].sort((a, b) => a.name.localeCompare(b.name)),
      currentScenarioId: scenario.id
    };
    return clearValues(state.ids as string[], updatedState);
  }),
);

const clearValues = (yearMonths: string[], updatedState: ScenarioState): ScenarioState => {
  return yearMonths.reduce((stateAcc: ScenarioState, yearMonth): ScenarioState => {
    const existingYearMonth = stateAcc.entities[yearMonth];
    if (existingYearMonth) {
      const accountsValues = existingYearMonth.accountsValues.map(accountValue => {
        return {
          ...accountValue,
          value: 0
        };
      });
      return adapter.upsertOne({ yearMonth, accountsValues }, stateAcc);
    } else {
      return stateAcc;
    }
  }, updatedState);
}

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of scenario accounts ids (yearmonths)
export const selectScenarioYearMonths = selectIds;

// select the dictionary of monthly scenario accounts
export const selectMonthlyScenarioStatesPerYearMonth = selectEntities;

// select the array of monthly scenario accounts
export const selectAllMonthlyScenarioStates = selectAll;

// select the total monthly scenario accounts count
export const selectMonthlyScenarioStateTotal = selectTotal;
