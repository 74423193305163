import { State } from '../../core/reducers';
import { createSelector } from '@ngrx/store';
import { selectMonthlyAccountsPerYearMonth, selectMonthlyAccountsYearMonths } from './monthly-accounts.reducer';
import { Dictionary } from '@ngrx/entity';
import { MonthlyAccounts } from '../model/accounting.model';
import { selectPrevBusinessYear, selectYearMonthSpan } from './span.selector';
import { getYearMonths } from '../model/period.model';
import { BexioBusinessYear } from '../model/bexio.model';

export const getMonthlyAccountsState = (state: State) => state.monthlyAccountsState;

export const selectMonthlyAccounts = createSelector(
  getMonthlyAccountsState,
  selectMonthlyAccountsPerYearMonth
);

export const selectMonths = createSelector(
  getMonthlyAccountsState,
  selectMonthlyAccountsYearMonths
)

export const selectExistingYearMonths = createSelector(
  selectYearMonthSpan,
  selectMonths,
  (allYearMonths: string[], existingYearMonths: string[] | number[]) => {
    const yearMonthsString = existingYearMonths.map(yearMonth => yearMonth.toString());
    return allYearMonths.filter(yearMonth => yearMonthsString.includes(yearMonth));
  });

export const selectMissingYearMonths = createSelector(
  selectYearMonthSpan,
  selectMonths,
  (allYearMonths: string[], existingYearMonths: string[] | number[]) => {
    const yearMonthsString = existingYearMonths.map(yearMonth => yearMonth.toString());
    return allYearMonths.filter(yearMonth => !yearMonthsString.includes(yearMonth));
  });

function getMonthlyAccounts(yearMonths: string[], monthlyStates: Dictionary<MonthlyAccounts>): MonthlyAccounts[] {
  return yearMonths.reduce((accounts: MonthlyAccounts[], yearMonth) => {
    const monthlyAccounts = monthlyStates[yearMonth];
    if (monthlyAccounts) {
      accounts.push(monthlyAccounts);
    }
    return accounts;
  }, []);
}

export const selectMonthlyAccountsWithinExistingSpan = createSelector(
  selectYearMonthSpan,
  selectMonthlyAccounts,
  getMonthlyAccounts
);

export const selectMonthlyAccountsWithinPrevSpan = createSelector(
  selectPrevBusinessYear,
  selectMonthlyAccounts,
  (businessYear: BexioBusinessYear | undefined, monthlyStates: Dictionary<MonthlyAccounts>): MonthlyAccounts[] => {
    if (businessYear) {
      const yearMonthSpan = getYearMonths(new Date(businessYear.start), new Date(businessYear.end));
      return getMonthlyAccounts(yearMonthSpan, monthlyStates);
    }
    return [];
  }
);
