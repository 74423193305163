import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { YearMonthEntries } from '../model/bexio.model';
import { getMonthlyEntries, getMonthlyEntriesError, getMonthlyEntriesSuccess } from './monthly-entries.action';
import { toYearMonthString } from '../model/period.model';
import { userSignOutSuccess } from '../../authentication/store/authentication.action';

export default interface MonthlyEntriesState extends EntityState<YearMonthEntries> {
  entriesLoading: boolean
}

export function selectId(a: YearMonthEntries): string {
  return toYearMonthString(a.yearMonth);
}

export function sortComparer(a: YearMonthEntries, b: YearMonthEntries): number {
  return toYearMonthString(a.yearMonth).localeCompare(toYearMonthString(b.yearMonth));
}

export const adapter = createEntityAdapter<YearMonthEntries>({
  selectId,
  sortComparer,
});

export const initialState: MonthlyEntriesState = adapter.getInitialState({
  entriesLoading: false
});

export const reducer = createReducer(
  initialState,
  on(userSignOutSuccess, (state): MonthlyEntriesState => adapter.removeAll(state)),
  on(getMonthlyEntries, (state, { yearMonths }): MonthlyEntriesState => ({ ...state, entriesLoading: yearMonths.length > 0 })),
  on(getMonthlyEntriesError, (state): MonthlyEntriesState => ({ ...state, entriesLoading: false })),
  on(getMonthlyEntriesSuccess, (state, { entries }): MonthlyEntriesState =>
    ({ ...adapter.upsertMany(entries, state), entriesLoading: false })),
);

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of monthly accounts ids (yearmonths)
export const selectMonthlyEntriesYearMonths = selectIds;

// select the dictionary of monthly accounts
export const selectMonthlyEntriesPerYearMonth = selectEntities;

// select the array of monthly accounts
export const selectAllMonthlyEntries = selectAll;

// select the total monthly accounts count
export const selectMonthlyEntriesTotal = selectTotal;
