<ng-container *transloco="let t; read: 'pricing'">
  <main>
    <div class="container">
      <img class="logo" src="../assets/logo-edelwise-horizontal.png" alt="Logo Edelwise by LEAF Technology"/>
      <p>{{ t('tagline') }}</p>
      <h3>{{ t('subtitle') }} {{ companyName$ | async }}</h3>
      <mat-button-toggle-group name="subscriptionModel" aria-label="Subscription Model" [(value)]="monthly">
        <mat-button-toggle [value]="true">{{ t('monthly') }}</mat-button-toggle>
        <mat-button-toggle [value]="false">{{ t('yearly') }}</mat-button-toggle>
      </mat-button-toggle-group>
      <p *ngIf="monthly" [innerHTML]="t('save-amount', { amount: 30 })"></p>
      <div *ngIf="monthly" class="price">
        <div class="price__value">CHF 17.50</div>
        <div class="price__period" [innerHTML]="t('per-month')"></div>
      </div>
      <div *ngIf="!monthly" class="price">
        <div class="price__value">CHF 180.00</div>
        <div class="price__period" [innerHTML]="t('per-year')"></div>
      </div>
      <button mat-flat-button color="primary"
              (click)="createCheckoutSessionClick()">{{ t('subscribe') }}</button>
    </div>
  </main>
</ng-container>
<app-footer></app-footer>