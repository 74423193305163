<header *transloco="let t">
  <div class="container">
    <div class="page-title">
      <h2>{{ t(title) }}<span *ngIf="beta"> (beta)</span></h2>
      <h3 *ngIf="(subtitle$ | async) as subtitle">{{ t(subtitle) }}</h3>
    </div>
    <div class="company-name-and-menu">
      <div *ngIf="(companyInfo$ | async) as companyInfo" class="company-name">
        <span>{{ companyInfo.name }}</span>
        <a href="https://office.bexio.com" target="_blank">office.bexio.com <fa-icon [icon]="externalLink"></fa-icon></a>
      </div>
      <button *ngIf="(isUserConnected$ | async)" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button (click)="changeCompanyClicked()" mat-menu-item>
          {{ t('app.switch-company') }}
        </button>
        <button (click)="logoutClicked()" mat-menu-item>{{ t('app.logout') }}</button>
      </mat-menu>
    </div>
  </div>
</header>
