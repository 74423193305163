import { State } from '../reducers';
import { createSelector } from '@ngrx/store';
import { RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from './route-serializer';


export const getRouterState = (state: State) => state.router;

export const selectComponentTitle = createSelector(
  getRouterState,
  (router: RouterReducerState<RouterStateUrl>) => router?.state.data?.title ?? ''
);

export const selectComponentSubtitle = createSelector(
  getRouterState,
  (router: RouterReducerState<RouterStateUrl>) => router?.state.data?.subtitle ?? ''
);

export const selectRouteUrl = createSelector(
  getRouterState,
  (router: RouterReducerState<RouterStateUrl>) => router?.state.url
);
