import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { Color } from '../../model/color.model';
import { DisplayMode } from '../../model/display-mode.model';
import { MonthlyGraphData } from '../../model/flow-table-row.model';
import { ChartService } from '../../service/chart.service';
import { ChfPipe } from '../../pipe/chf.pipe';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('chart') canvas: ElementRef<HTMLCanvasElement> | undefined;

  @Input() loading = true;
  @Input() realInputOutput?: MonthlyGraphData;
  @Input() budgetInputOutput?: MonthlyGraphData;
  @Input() yearMonths: string[] = [];
  @Input() displayMode: DisplayMode = DisplayMode.mix;
  @Input() inputColor: Color = Color.buildGreen();
  @Input() outputColor: Color = Color.buildRed();
  @Input() resultColor: Color = Color.buildBlue();
  @Input() inputText: string = '';
  @Input() outputText: string = '';
  @Input() resultText: string = '';
  @Input() cumulated: boolean = false;
  @Input() translocoChange: string|undefined;

  chart: Chart | undefined;

  constructor(
    private chartService: ChartService,
    private chfPipe: ChfPipe,
  ) {
  }

  async ngAfterViewInit(): Promise<void> {
    await this.createChart();
  }

  async ngOnChanges(): Promise<void> {
    await this.createChart();
  }

  async createChart(): Promise<void> {
    if (this.canvas && this.realInputOutput) {

      const realDataset = this.realInputOutput ?
        await this.chartService.createDataset(
          this.realInputOutput,
          this.cumulated,
          this.inputColor,
          `${this.inputText}-real`,
          this.outputColor,
          `${this.outputText}-real`,
          this.resultColor,
          `${this.resultText}-real`,
          this.yearMonths
        ) : [];
      const budgetDataset = this.budgetInputOutput ?
        await this.chartService.createDataset(
          this.budgetInputOutput,
          this.cumulated,
          this.inputColor,
          `${this.inputText}-budget`,
          this.outputColor,
          `${this.outputText}-budget`,
          this.resultColor,
          `${this.resultText}-budget`,
        ) : [];
      let datasets;
      switch (this.displayMode) {
        case DisplayMode.mix:
          datasets = realDataset.concat(budgetDataset);
          break;
        case DisplayMode.real:
          datasets = realDataset;
          break;
        case DisplayMode.budget:
          datasets = budgetDataset;
          break;
      }

      this.chart?.destroy();
      this.chart = new Chart(this.canvas.nativeElement, {
        data: {
          labels: this.yearMonths.map(yearMonth => yearMonth.charAt(0).toUpperCase() + yearMonth.slice(1)),
          datasets
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            x: {
              ticks: {
                color: '#999',
              },
              grid: {
                display: false
              },
              border: {
                display: false
              }
            },
            y: {
              ticks: {
                color: '#999',
              },
              stacked: false,
              grid: {
                display: true
              },
              border: {
                display: false
              }
            },
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: (item: any) => `${item.dataset.label}: CHF ${this.chfPipe.transform(item.raw)}`,
              },
            },
            legend: {
              display: false
            }
          }
        }
      });
    }
  }
}
