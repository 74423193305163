import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import AppState, * as fromApp from '../store/app.reducer';
import AccountingState, * as fromAccounting from '../../dashboard/store/accounting.reducer';
import ScenarioState, * as fromScenario from '../../dashboard/store/scenario.reducer';
import SpanState, * as fromSpan from '../../dashboard/store/span.reducer';
import MonthlyAccountsState, * as fromMonthlyAccounts from '../../dashboard/store/monthly-accounts.reducer';
import MonthlyEntriesState, * as fromMonthlyEntries from '../../dashboard/store/monthly-entries.reducer';
import AuthenticationState, * as fromAuthentication from '../../authentication/store/authentication.reducer';
import ProfileState, * as fromPayment from "../../profile/store/profile.reducer";
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../router/route-serializer';

export interface State {
  appState: AppState;
  accountingState: AccountingState;
  scenarioState: ScenarioState;
  spanState: SpanState;
  monthlyEntriesState: MonthlyEntriesState;
  monthlyAccountsState: MonthlyAccountsState;
  authenticationState: AuthenticationState;
  paymentState: ProfileState;
  router: RouterReducerState<RouterStateUrl>;
}

export const reducers: ActionReducerMap<State> = {
  appState: fromApp.reducer,
  accountingState: fromAccounting.reducer,
  scenarioState: fromScenario.reducer,
  spanState: fromSpan.reducer,
  monthlyEntriesState: fromMonthlyEntries.reducer,
  monthlyAccountsState: fromMonthlyAccounts.reducer,
  authenticationState: fromAuthentication.reducer,
  paymentState: fromPayment.reducer,
  router: routerReducer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
