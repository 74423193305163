import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { getMonthlyEntriesError } from '../../dashboard/store/monthly-entries.action';
import { map } from 'rxjs/operators';
import { captureException, captureMessage } from '@sentry/angular-ivy';
import { CaptureContext } from '@sentry/types';
import { Store } from '@ngrx/store';
import { selectConnectedUser } from '../../authentication/store/authentication.selector';
import { State } from '../../core/reducers';
import { NotificationService } from '../service/notification.service';
import { filter } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { paymentCancelled } from '../../profile/store/profile.actions';
import { selectPreferredLanguage } from '../../profile/store/profile.selector';

@Injectable({
  providedIn: 'root',
})
export class ErrorReportingEffects {

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
  ) {
  }

  accountingForbiddenEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMonthlyEntriesError),
      filter(({error}) => error.status === 403),
      concatLatestFrom(() => [
        this.store.select(selectConnectedUser)
      ]),
      map(([{error}, user]) => {
        const context: CaptureContext = {
          user: {
            id: user?.id,
            email: user?.email
          }
        };
        captureException(error, context);
        const message = this.translocoService.translate('errors.no-accounting-access');
        this.notificationService.flashWarning(message);
      })
    );
  }, { dispatch: false });

  paymentCancelledEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(paymentCancelled),
      concatLatestFrom(() => [
        this.store.select(selectConnectedUser),
        this.store.select(selectPreferredLanguage),
      ]),
      map(([, user, lang]) => {
        const context: CaptureContext = {
          user: {
            id: user?.id,
            email: user?.email,
            preferredLanguage: lang
          }
        };
        captureMessage('payment-cancelled', context);
        this.translocoService.load(lang).subscribe(() => {
          const message = this.translocoService.translate('errors.payment-cancelled');
          this.notificationService.flashWarning(message);
        });
      })
    );
  }, { dispatch: false });
}
