import { Component } from '@angular/core';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from '../../../shared/shared.module';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    FaIconComponent,
    MatIconModule,
    SharedModule,
    TranslocoDirective
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {

  protected readonly at = faEnvelope;
  protected readonly currentYear = new Date().getFullYear();
  protected readonly instagram = faInstagram;
  protected readonly linkedin = faLinkedin;
}
