import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { MonthlyAccounts } from '../model/accounting.model';
import { processedMonthlyEntriesIntoAccountStates } from './monthly-accounts.action';
import { userSignOutSuccess } from '../../authentication/store/authentication.action';

export default interface MonthlyAccountsState extends EntityState<MonthlyAccounts> {
}

export function selectId(a: MonthlyAccounts): string {
  return a.yearMonth;
}

export function sortComparer(a: MonthlyAccounts, b: MonthlyAccounts): number {
  return a.yearMonth.localeCompare(b.yearMonth);
}

export const adapter = createEntityAdapter<MonthlyAccounts>({
  selectId,
  sortComparer,
});

export const initialState: MonthlyAccountsState = adapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(userSignOutSuccess, (state): MonthlyAccountsState => adapter.removeAll(state)),
  on(processedMonthlyEntriesIntoAccountStates, (state, { monthlyAccountsList }): MonthlyAccountsState =>
    adapter.upsertMany(monthlyAccountsList, state)),
);

// get the selectors
const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();

// select the array of monthly accounts ids (yearmonths)
export const selectMonthlyAccountsYearMonths = selectIds;

// select the dictionary of monthly accounts
export const selectMonthlyAccountsPerYearMonth = selectEntities;

// select the array of monthly accounts
export const selectAllMonthlyAccounts = selectAll;

// select the total monthly accounts count
export const selectMonthlyAccountsTotal = selectTotal;
