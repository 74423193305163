import { FlowTableRow, MonthlyGraphData, ROW_TYPE } from '../model/flow-table-row.model';
import { createSelector } from '@ngrx/store';
import AccountingState from './accounting.reducer';
import {
  AccountingMode,
  AccountNode,
  createRowNode,
  INITIAL_TREASURY_ACCOUNT,
  MonthlyAccounts,
  TREASURY_ACCOUNT_GROUP
} from '../model/accounting.model';
import { State } from '../../core/reducers';
import {
  selectBusinessYears,
  selectFirstDayOfCurrentBusinessYear,
  selectPrevBusinessYear,
  selectStartDate,
  selectYearMonthSpan,
} from './span.selector';
import {
  BexioAccount,
  BexioAccountGroup,
  BexioBusinessYear,
  ParsedBexioEntry,
  YearMonthEntries
} from '../model/bexio.model';
import {
  selectMonthlyAccounts,
  selectMonthlyAccountsWithinExistingSpan,
  selectMonthlyAccountsWithinPrevSpan,
} from './monthly-accounts.selector';
import { selectMonthlyEntriesWithinExistingSpan } from './monthly-entries.selector';
import { fromBusinessYear, getYearMonths } from '../model/period.model';
import { Dictionary } from '@ngrx/entity';

export const getAccountingState = (state: State): AccountingState => state.accountingState;
export const selectCompanyInfo = createSelector(getAccountingState, (state: AccountingState) => state.companyInfo);
export const selectCompanyName = createSelector(selectCompanyInfo, (companyInfo) => companyInfo?.name);
export const selectCurrencies = createSelector(getAccountingState, (state: AccountingState) => state.currencies);
export const selectCurrencyMap = createSelector(
  selectCurrencies,
  (currencies): Map<number, number> => currencies.reduce(
    (acc, curr) => curr.factor ? acc.set(curr.currencyId, curr.factor) : acc,
    new Map<number, number>()
  )
);
export const selectAccounts = createSelector(getAccountingState, (state: AccountingState) => state.accounts);
export const selectAccountGroups = createSelector(getAccountingState, (state: AccountingState) => state.accountGroups);
export const selectAccountHierarchy = createSelector(getAccountingState, (state: AccountingState): AccountNode | null => state.accountHierarchy);
export const selectOpenedAccounts = createSelector(getAccountingState, (state: AccountingState): number[] => state.openedAccounts);
export const selectAccountingMode = createSelector(getAccountingState, (state: AccountingState): AccountingMode => state.accountingMode);
export const selectInitialTreasuryAccountId = createSelector(
  selectAccounts,
  (accounts: BexioAccount[]): number | undefined => {
    return accounts.find(account => account.account_no === INITIAL_TREASURY_ACCOUNT)?.id;
  });
export const selectTreasuryAccountIds = createSelector(
  selectAccountGroups,
  selectAccounts,
  (accountGroups: BexioAccountGroup[], accounts: BexioAccount[]): number[] => {
    const treasuryGroupId = accountGroups.find(accountGroup => accountGroup.account_no === TREASURY_ACCOUNT_GROUP)?.id;
    return accounts.filter(account => account.fibu_account_group_id === treasuryGroupId).map(account => account.id);
  });
export const selectTreasuryAccountNumbers = createSelector(
  selectAccountGroups,
  selectAccounts,
  (accountGroups: BexioAccountGroup[], accounts: BexioAccount[]): string[] => {
    const treasuryGroupId = accountGroups.find(accountGroup => accountGroup.account_no === TREASURY_ACCOUNT_GROUP)?.id;
    return accounts.filter(account => account.fibu_account_group_id === treasuryGroupId).map(account => account.account_no);
  });
export const selectInitialTreasury = createSelector(getAccountingState, (state: AccountingState) => state.initialTreasury);

export const selectInitialTreasuryValid = createSelector(
  selectFirstDayOfCurrentBusinessYear,
  selectStartDate,
  (businessYearStartDate, spanStartDate) =>
    businessYearStartDate && businessYearStartDate.getTime() === spanStartDate.getTime()
);

export const selectCashFlowGraphData = createSelector(
  selectYearMonthSpan,
  selectAccounts,
  selectTreasuryAccountIds,
  selectInitialTreasuryAccountId,
  selectMonthlyEntriesWithinExistingSpan,
  selectCurrencyMap,
  selectInitialTreasury,
  (yearMonthSpan, accounts, treasuryAccountIds, initialTreasuryAccountId, yearMonthEntries: YearMonthEntries[], currencies, initialCash): MonthlyGraphData | undefined => {
    if (yearMonthEntries.length === yearMonthSpan.length) {
      return yearMonthEntries.reduce((graphData: MonthlyGraphData, entries: YearMonthEntries, index) => {
        const filteredEntries = entries.entries
          .filter(entry => entry.credit_account_id !== initialTreasuryAccountId)
          .reduce((entries, entry) => {
              if (treasuryAccountIds.includes(entry.debit_account_id) && !treasuryAccountIds.includes(entry.credit_account_id)) {
                entries.cashInEntries.push(entry);
              } else if (treasuryAccountIds.includes(entry.credit_account_id) && !treasuryAccountIds.includes(entry.debit_account_id)) {
                entries.cashOutEntries.push(entry);
              }
              return entries;
            }, {
              cashInEntries: [],
              cashOutEntries: []
            } as { cashInEntries: ParsedBexioEntry[]; cashOutEntries: ParsedBexioEntry[] }
          );
        const cashIn = filteredEntries.cashInEntries.reduce((sum, value) => sum + value.amount * (currencies.get(value.currency_id) ?? 1), 0);
        const cashOut = filteredEntries.cashOutEntries.reduce((sum, value) => sum + value.amount * (currencies.get(value.currency_id) ?? 1), 0);
        const result = cashIn - cashOut;
        const treasury = (index > 0 ? graphData.treasury[index - 1] : initialCash) + result;
        graphData.input.push(cashIn);
        graphData.output.push(cashOut);
        graphData.result.push(result);
        graphData.treasury.push(treasury);
        return graphData;
      }, {
        input: [],
        output: [],
        result: [],
        treasury: []
      } as MonthlyGraphData);
    } else {
      return undefined;
    }
  }
);

function getRealTableData(yearMonthSpan: string[], rootNode: AccountNode | null, monthlyAccounts: MonthlyAccounts[], openedAccounts: number[]): FlowTableRow[] | undefined {
  if (rootNode && monthlyAccounts.length === yearMonthSpan.length) {
    const rootNodes = createRowNode(rootNode, 0, monthlyAccounts, openedAccounts).children;
    return [
      rootNodes[0], { ...rootNodes[0], name: 'result-operating', children: [], rowType: ROW_TYPE.recap },
    ];
  }
  return undefined;
}

export const selectRealTableData = createSelector(
  selectYearMonthSpan,
  selectAccountHierarchy,
  selectMonthlyAccountsWithinExistingSpan,
  selectOpenedAccounts,
  getRealTableData
);

export const selectPrevYearRealTableData = createSelector(
  selectPrevBusinessYear,
  selectAccountHierarchy,
  selectMonthlyAccountsWithinPrevSpan,
  selectOpenedAccounts,
  (businessYear, rootNode, monthlyAccounts: MonthlyAccounts[], openedAccounts: number[]): FlowTableRow[] | undefined => {
    if (businessYear) {
      const yearMonthSpan = getYearMonths(new Date(businessYear.start), new Date(businessYear.end));
      return getRealTableData(yearMonthSpan, rootNode, monthlyAccounts, openedAccounts);
    }
    return undefined;
  }
);

export const selectRealInputData = createSelector(
  selectRealTableData,
  (tableData: FlowTableRow[] | undefined): number[] => {
    if (tableData && tableData.length) {
      const length = tableData[0].values.length
      const inputRows = [
        tableData[0].children[0].values,
        // tableData[1].values // TODO properly handle these values
      ];
      return inputRows.reduce((cur, acc) => {
          return cur.map((value, index) => acc[index] + value)
        }
        , Array.from({ length }).fill(0) as number[]);
    }
    return [];
  }
);

const ACTIVITY_OPERATING_INDEX = 0;
// const INCOME_ACCOUNT_NUMBER = 3;
// const MATERIAL_EXPENSES_ACCOUNT_NUMBER = 4;
// const PERSONNEL_EXPENSES_ACCOUNT_NUMBER = 5;

export const selectRealOutputData = createSelector(
  selectRealTableData,
  (tableData: FlowTableRow[] | undefined): number[] => {
    if (tableData && tableData.length) {
      // console.log(tableData);
      const spanLength = tableData[ACTIVITY_OPERATING_INDEX].values.length
      const inputRows: number[][] = [
        // TODO properly handle these values
        // tableData[ACTIVITY_OPERATING_INDEX].children.find(child => child.accountNo === INCOME_ACCOUNT_NUMBER)?.values ?? [],
        // tableData[ACTIVITY_OPERATING_INDEX].children.find(child => child.accountNo === MATERIAL_EXPENSES_ACCOUNT_NUMBER)?.values ?? [],
        // tableData[ACTIVITY_OPERATING_INDEX].children.find(child => child.accountNo === PERSONNEL_EXPENSES_ACCOUNT_NUMBER)?.values ?? [],
        tableData[ACTIVITY_OPERATING_INDEX].children.length > 1 ? tableData[ACTIVITY_OPERATING_INDEX].children[1].values : [],
        tableData[ACTIVITY_OPERATING_INDEX].children.length > 2 ? tableData[ACTIVITY_OPERATING_INDEX].children[2].values : [],
        tableData[ACTIVITY_OPERATING_INDEX].children.length > 3 ? tableData[ACTIVITY_OPERATING_INDEX].children[3].values : [],
        // TODO properly handle these values
        // tableData[ACTIVITY_OPERATING_INDEX].children[4].values,
        // tableData[ACTIVITY_OPERATING_INDEX].children[5].values,
        // tableData[2].values
      ];
      return inputRows.reduce((cur, acc) => {
          return cur.map((value, index) => acc[index] + value)
        }
        , Array.from({ length: spanLength }).fill(0) as number[]);
    }
    return [];
  }
);

export const selectRealTreasuryData = createSelector(
  selectRealTableData,
  (tableData: FlowTableRow[] | undefined): FlowTableRow | undefined => {
    if (tableData && tableData.length) {
      const flowTableRow = tableData[tableData.length - 1];
      return {...flowTableRow, values: flowTableRow.values.reduce((cur, acc, index) => {
        if (index === 0) {
          return [acc];
        }
        return [...cur, acc + cur[index - 1] ?? 0]
      }, [] as number[]) }
    }
    return undefined;
  }
);

export const selectRealGraphData = createSelector(
  selectRealInputData,
  selectRealOutputData,
  selectRealTreasuryData,
  (cashIn, cashOut, treasuryRows) => {
    const result = cashIn.map((value, index) => value - cashOut[index]);
    const treasury = treasuryRows?.values ?? [];
    return {
      input: cashIn,
      output: cashOut,
      result,
      treasury
    } as MonthlyGraphData;
  }
);

export const selectThreePreviousBusinessYears = createSelector(
  selectBusinessYears,
  (businessYears): BexioBusinessYear[] => {
    if (businessYears.length < 2) {
      return [];
    }
    const businessYearsWithoutCurrentOne = businessYears.slice(0, businessYears.length - 1);
    while (businessYearsWithoutCurrentOne.length > 3) {
      businessYearsWithoutCurrentOne.shift();
    }
    return businessYearsWithoutCurrentOne;
  }
);

export const selectAverageResultFromLastThreePreviousYears = createSelector(
  selectAccountHierarchy,
  selectMonthlyAccounts,
  selectThreePreviousBusinessYears,
  (rootNode, allMonthlyAccounts: Dictionary<MonthlyAccounts>, businessYears): number => {
    const sum = businessYears.reduce((totalResult, businessYear) => {
      const yearMonthSpan = fromBusinessYear(businessYear);
      const monthlyAccountsWithinSpan = yearMonthSpan.reduce((accounts: MonthlyAccounts[], yearMonth) => {
        const monthlyAccounts = allMonthlyAccounts[yearMonth];
        if (monthlyAccounts) {
          const monthlyAccountsValuesToConsider = monthlyAccounts.accountsValues.filter(accountValue => Number(accountValue.account_no) < 6800);
          accounts.push({
            yearMonth: monthlyAccounts.yearMonth,
            accountsValues: monthlyAccountsValuesToConsider
          });
        }
        return accounts;
      }, []);
      if (rootNode && monthlyAccountsWithinSpan.length === yearMonthSpan.length) {
        const rootNodes = createRowNode(rootNode, 0, monthlyAccountsWithinSpan, []).children;
        return rootNodes[0].total + totalResult;
      } else {
        return totalResult;
      }
    }, 0);
    return sum / businessYears.length;
  }
);

export const selectValuationFromAverageResult = (capitalisationRate: number) => createSelector(
  selectAverageResultFromLastThreePreviousYears,
  (averageResult): number => averageResult / capitalisationRate
);
