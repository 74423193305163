import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { OAuthProvider } from '@firebase/auth';
import { oauthProviderFactory } from './authentication-oauth.provider';

@NgModule({
  declarations: [],
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    RouterModule,
    MatInputModule,
    MatButtonModule,
    CommonModule,
    TranslocoModule,
  ],
  exports: [],
  providers: [
    {
      provide: OAuthProvider,
      useFactory: oauthProviderFactory
    }
  ],
})
export class AuthenticationModule { }
