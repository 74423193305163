<footer *transloco="let t; read: 'app'">
  <div class="container">
    <p class="love">{{ t('made-with') }}
      <mat-icon>favorite</mat-icon>
      {{ t('by-leaf') }} — <a href="https://leaf.swiss">leaf.swiss</a>
    </p>
    <p class="legal">© {{ t('all-rights-reserved') }} 2018-{{ currentYear }}</p>
    <div class="footer-options">
      <app-lang-selector></app-lang-selector>
      <div class="social-network">
        <a href="https://ch.linkedin.com/company/leaf-swiss" target="_blank" aria-label="Linkedin">
          <fa-icon [icon]="linkedin"></fa-icon>
        </a>
        <a href="https://www.instagram.com/leaf.swiss/" target="_blank" aria-label="Instagram">
          <fa-icon [icon]="instagram"></fa-icon>
        </a>
        <a href="mailto:info@leaf.swiss" target="_blank" aria-label="{{ t('contact') }}">
          <fa-icon [icon]="at"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</footer>
