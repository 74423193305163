import { Component, Inject } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-html-notification',
  templateUrl: './html-notification.component.html',
  styleUrls: ['./html-notification.component.scss'],
})
export class HtmlNotificationComponent {
  readonly htmlMessage = this.data.htmlMessage;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: { htmlMessage: string },
    readonly snackBar: MatSnackBar,
  ) {}

}
