import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Color } from '../../model/color.model';
import { Store } from '@ngrx/store';
import {
  selectCompanyName,
  selectRealGraphData,
  selectRealTableData,
} from '../../store/accounting.selector';
import { DisplayMode } from '../../model/display-mode.model';
import { Observable } from 'rxjs';
import { changeEndYearMonth, changeStartYearMonth } from '../../store/span.action';
import { selectEndYearMonth, selectStartYearMonth } from '../../store/span.selector';
import { YearMonth } from '../../model/period.model';
import {
  selectCurrentScenarioId,
  selectScenarioGraphData,
  selectScenarios,
  selectScenarioTableData
} from '../../store/scenario.selector';
import {
  createScenario,
  deleteScenario,
  downloadPreviousYearCsv,
  duplicateScenario,
  getScenarioValues,
  setScenarioName
} from '../../store/scenario.action';
import { Scenario } from '../../store/scenario.model';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { DashboardService } from '../../service/dashboard.service';
import { selectIsUserConnected, selectRemainingDays } from '../../../authentication/store/authentication.selector';
import {
  selectHasAccessToPaidFeatures,
  selectIsCompanySubscriptionValid
} from '../../../profile/store/profile.selector';
import { selectComponentTitle } from '../../../core/router/router-store.selector';
import { selectEntriesLoading } from '../../store/monthly-entries.selector';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss', '../common/common.component.scss']
})
export class AccountingComponent {
  @ViewChild('main') main: ElementRef<HTMLInputElement> | undefined;

  title$ = this.store.select(selectComponentTitle);
  entriesLoading$ = this.store.select(selectEntriesLoading);
  companyName$ = this.store.select(selectCompanyName);
  startYearMonth$ = this.store.select(selectStartYearMonth);
  endYearMonth$ = this.store.select(selectEndYearMonth);
  realTableData$ = this.store.select(selectRealTableData);
  realGraphData$ = this.store.select(selectRealGraphData);
  budgetTableData$ = this.store.select(selectScenarioTableData);
  budgetGraphData$ = this.store.select(selectScenarioGraphData);
  scenarios$ = this.store.select(selectScenarios);
  selectedScenarioId$ = this.store.select(selectCurrentScenarioId);
  locale$ = this.translocoLocaleService.localeChanges$;
  yearMonths$: Observable<string[]>;
  isUserConnected$ = this.store.select(selectIsUserConnected);
  remainingDays$: Observable<number> = this.store.select(selectRemainingDays);
  isCompanySubscriptionValid$: Observable<boolean | null> = this.store.select(selectIsCompanySubscriptionValid);
  hasAccessToPaidFeatures$: Observable<boolean | null> = this.store.select(selectHasAccessToPaidFeatures);
  displayMode: DisplayMode = DisplayMode.mix;
  displayModeList: string[] = Object.values(DisplayMode);
  navFixed: boolean = false;
  inputColor: Color = new Color(76, 175, 80);
  outputColor: Color = new Color(244, 67, 54);
  treasuryColor: Color = new Color(33, 150, 243);

  constructor(
    private store: Store<any>,
    dashboardService: DashboardService,
    private translocoLocaleService: TranslocoLocaleService,
  ) {
    this.yearMonths$ = dashboardService.getFormattedYearMonth();
  }

  @HostListener('scroll', ['$event'])
  onScroll(scrollEvent: any) {
    this.navFixed = scrollEvent.target?.scrollTop > (this.main?.nativeElement.offsetTop || 0);
  }

  changeStartYearMonth = (startYearMonth: YearMonth) =>
    this.store.dispatch(changeStartYearMonth({ year: startYearMonth.year, month: startYearMonth.month }));

  changeEndYearMonth = (endYearMonth: YearMonth) =>
    this.store.dispatch(changeEndYearMonth({ year: endYearMonth.year, month: endYearMonth.month }));

  loadScenario = (scenarioId: string) => this.store.dispatch(getScenarioValues({ scenarioId }));

  removeScenario = (scenarioId: string) => this.store.dispatch(deleteScenario({ scenarioId }));

  renameScenario = (scenarioWithNewName: Scenario) => this.store.dispatch(setScenarioName({
    scenarioId: scenarioWithNewName.id,
    newName: scenarioWithNewName.name
  }));

  copyScenario = (scenario: Scenario) => this.store.dispatch(duplicateScenario({ scenarioId: scenario.id, scenarioName: scenario.name }));

  createNewScenario = (scenario: { name: string, initDataWithPreviousYear: boolean }) => {
    this.store.dispatch(createScenario({
      scenarioName: scenario.name,
      initDataWithPreviousYear: scenario.initDataWithPreviousYear
    }));
  };

  downloadScenarioCsv = () => this.store.dispatch(downloadPreviousYearCsv());
}
