import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'chf'
})
export class ChfPipe implements PipeTransform {

  transform(value: number | undefined, fractionDigits: number = 2, displaySymbol = false): string | undefined {
    return `${displaySymbol ? 'CHF ' : ''}${value?.toLocaleString('fr-CH', {
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: 2
    }).replace(/\s/, '\'').replace(',', '.').replace('-0.00', '0.00')}`;
  }

}
