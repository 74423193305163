<app-header [title]="title$ | async"></app-header>
<ng-container *ngIf="realGraphData$ | async as realGraphData">
  <ng-container *transloco="let t; read: 'financial-analysis'">
    <div class="indicators">
      <app-mini-chart [name]="t('turn-over')" [dataSource]="realGraphData.input" [yearMonths]="yearMonths$ | async"
                      [color]="inputColor" [loading]="entriesLoading$ | async" cumulated="true"></app-mini-chart>
      <app-mini-chart [name]="t('charges')" [dataSource]="realGraphData.output" [yearMonths]="yearMonths$ | async"
                      [color]="outputColor" [loading]="entriesLoading$ | async" cumulated="true"></app-mini-chart>
      <app-mini-chart [name]="t('result')" [dataSource]="realGraphData.treasury" [yearMonths]="yearMonths$ | async"
                      [color]="treasuryColor" [loading]="entriesLoading$ | async"></app-mini-chart>
    </div>
    <main #main>
      <nav [ngClass]="{ 'fixed': navFixed }">
        <div class="container">
            <div class="content">
              <div class="content-left">
                <mat-button-toggle-group [(ngModel)]="displayMode"
                                         name="modeTable" aria-label="{{ t('table-mode-info') }}">
                  <mat-button-toggle *ngFor="let displayModeOption of displayModeList" [value]="displayModeOption">
                    {{ t('table-mode', { option: displayModeOption }) }}
                  </mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="content-right">
                <app-period-selector [startYearMonth]="startYearMonth$ | async"
                                     [endYearMonth]="endYearMonth$ | async"
                                     (startYearMonthChange)="changeStartYearMonth($event)"
                                     (endYearMonthChange)="changeEndYearMonth($event)"
                ></app-period-selector>
                <app-scenario-selector [scenarios]="scenarios$ | async"
                                       [selectedId]="selectedScenarioId$ | async"
                                       [disabled]="(hasAccessToPaidFeatures$ | async) === false || (isUserConnected$ | async) === false || displayMode === 'real'"
                                       (selectedIdChange)="loadScenario($event)"
                                       (scenarioIdRemoved)="removeScenario($event)"
                                       (scenarioRenamed)="renameScenario($event)"
                                       (scenarioDuplicated)="copyScenario($event)"
                                       (scenarioCreated)="createNewScenario($event)"
                ></app-scenario-selector>
              </div>
            </div>
        </div>
      </nav>
      <div *ngIf="(isCompanySubscriptionValid$ | async) === false">
        <div *ngIf="(remainingDays$ | async) > 0; else noRemainingDays" class="message-container container">
          <div class="message-indicator"></div>
          <div class="message-body">
            <h5>{{ t('free-try-title') }}</h5>
            <p>{{ t('free-try-text', { remainingDays: (remainingDays$ | async) }) }} {{ t('subscribe-prefix') }} <a routerLink="/pricing" [innerHTML]="t('subscribe-link', { companyName: (companyName$ | async) })"></a></p>
          </div>
        </div>
        <ng-template #noRemainingDays>
          <div *ngIf="(hasAccessToPaidFeatures$ | async) === false" class="message-container container error">
            <div class="message-indicator"></div>
            <div class="message-body">
              <h5>{{ t('no-subscription-title') }}</h5>
              <p>{{ t('no-subscription-text') }} {{ t('subscribe-prefix') }} <a routerLink="/pricing" [innerHTML]="t('subscribe-link', { companyName: (companyName$ | async) })"></a></p>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="container">
        <ng-container *ngIf="budgetGraphData$ | async as budgetGraphData">
          <app-chart [realInputOutput]="realGraphData"
                     [budgetInputOutput]="budgetGraphData"
                     [yearMonths]="yearMonths$ | async"
                     [displayMode]="displayMode"
                     [inputColor]="inputColor"
                     inputText="financial-analysis.turn-over"
                     [outputColor]="outputColor"
                     outputText="financial-analysis.charges"
                     [resultColor]="treasuryColor"
                     resultText="financial-analysis.result"
                     [cumulated]="true"
                     [translocoChange]="locale$ | async"
                     [loading]="entriesLoading$ | async"
          ></app-chart>
        </ng-container>
        <app-flow-table [realDataSource]="realTableData$ | async"
                        [budgetDataSource]="budgetTableData$ | async"
                        [displayMode]="displayMode"
                        (downloadCsv)="downloadScenarioCsv()"
                        [loading]="entriesLoading$ | async"
        ></app-flow-table>
      </div>
    </main>
  </ng-container>
</ng-container>
<app-footer></app-footer>
