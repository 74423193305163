import { createReducer, on } from '@ngrx/store';
import { userSignOutSuccess } from '../../authentication/store/authentication.action';
import { clearLoading, hideLoading, showLoading } from './app.actions';

export default interface AppState {
  loading: number;
}

export const initialState: AppState = {
  loading: 0,
};

export const reducer = createReducer(
  initialState,
  on(userSignOutSuccess, clearLoading, (): AppState => initialState),
  on(showLoading, (state): AppState => ({...state, loading: state.loading + 1})),
  on(hideLoading,(state): AppState => ({...state, loading: Math.max(state.loading - 1, 0)})),
);
