import { createAction, props } from '@ngrx/store';
import {
  BexioAccount,
  BexioAccountGroup,
  BexioBusinessYear,
  BexioCompanyInfo,
  BexioEntry,
  CurrencyExchangeFactor,
} from '../model/bexio.model';
import { AccountingMode, AccountNode } from '../model/accounting.model';

export const getReferenceData = createAction('[Reference] get reference data');
export const getReferenceDataSuccess = createAction(
  '[Reference] get reference data success',
  props<{
    company: BexioCompanyInfo,
    currencies: CurrencyExchangeFactor[],
    accounts: BexioAccount[],
    accountGroups: BexioAccountGroup[],
    businessYears: BexioBusinessYear[],
  }>()
);
export const getReferenceDataError = createAction('[Reference] get reference data error', props<{ error: string }>());

export const getAccountHierarchySuccess = createAction(`[Reference] get account hierarchy success`, props<{ root: AccountNode }>());

export const toggleOpenedStatus = createAction('[FlowTable] toggle account opened status', props<{ accountNo: number }>());

export const displayIntermediaryAccountGroupsChanged = createAction(
  '[FlowTable] display intermediary account groups changed',
  props<{ enabled: boolean }>()
);

export const openAllRows = createAction('[FlowTable] open all rows for account');
export const openFirstLevel = createAction('[FlowTable] open first level for account');
export const closeAllRows = createAction('[FlowTable] close all rows for account');


export const setAccountingMode = createAction('[Accounting] set accounting mode', props<{ mode: AccountingMode }>());

export const getTreasuryEntriesForCurrentBusinessYear = createAction('[Accounting] get treasury entries');
export const getTreasuryEntriesForCurrentBusinessYearSuccess = createAction(
  '[Accounting] get treasury entries success',
  props<{ entries: BexioEntry[] }>()
);
export const getTreasuryEntriesForCurrentBusinessYearError = createAction(
  '[Accounting] get treasury entries error',
  props<{ error: Error }>()
);
export const setInitialTreasury = createAction('[Accounting] set initial treasury', props<{ initialTreasury: number }>());
