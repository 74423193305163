<div class="content" *transloco="let t; read: 'dialog.budget'">
  <h2>{{ t('rename') }}</h2>
  <div class="info">
    <mat-form-field appearance="outline">
      <mat-label>{{ t('name') }}</mat-label>
      <input matInput type="text" [(ngModel)]="name" (keyup.enter)="validate()">
    </mat-form-field>
  </div>
  <mat-dialog-actions align="end">
    <button mat-flat-button color="primary" class="mat-button-base" (click)="validate()"><span>{{ t('rename-action') }}</span></button>
    <button mat-button class="mat-button-base" (click)="cancel()"><span>{{ t('cancel') }}</span></button>
  </mat-dialog-actions>
</div>
