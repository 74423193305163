import { State } from '../../core/reducers';
import { createSelector } from '@ngrx/store';
import { getYearMonths, buildYearMonthString } from '../model/period.model';
import SpanState from './span.reducer';
import { BexioBusinessYear } from '../model/bexio.model';

export const getSpanState = (state: State) => state.spanState;

export const selectStartYearMonth = createSelector(
  getSpanState,
  (state: SpanState) => buildYearMonthString(state.startYear, state.startMonth)
);

export const selectStartDate = createSelector(
  getSpanState,
  (state: SpanState) => new Date(state.startYear, state.startMonth)
);

export const selectEndYearMonth = createSelector(
  getSpanState,
  (state: SpanState) => buildYearMonthString(state.endYear ?? state.startYear, state.endMonth)
);

export const selectBusinessYears = createSelector(getSpanState, (state: SpanState): BexioBusinessYear[] => state.businessYears);
export const selectBusinessYearsTotalSpan = createSelector(
  selectBusinessYears,
  (businessYears): string[] => {
    if (businessYears.length === 0) {
      return [];
    }
    const startDate = new Date(businessYears[0].start);
    startDate.setHours(0);
    const endDate = new Date(businessYears[businessYears.length - 1].end);
    endDate.setHours(0);
    return getYearMonths(startDate, endDate);
  }
);

function getIndexBexioBusinessYear(businessYears: BexioBusinessYear[], periodStartDate: Date): number {
  const periodStartDateTime = periodStartDate.getTime();
  return businessYears.findIndex(businessYear => {
    const startDate = new Date(businessYear.start);
    startDate.setHours(0);
    const endDate = new Date(businessYear.end);
    endDate.setHours(0);
    return periodStartDateTime >= startDate.getTime() && periodStartDateTime <= endDate.getTime();
  });
}

export const selectCurrentBusinessYear = createSelector(
  selectBusinessYears,
  selectStartDate,
  (businessYears: BexioBusinessYear[], periodStartDate: Date): BexioBusinessYear | undefined => {
    const index = getIndexBexioBusinessYear(businessYears, periodStartDate);
    return index >= 0 ? businessYears[index] : undefined;
  }
);

export const selectPrevBusinessYear = createSelector(
  selectBusinessYears,
  selectStartDate,
  (businessYears: BexioBusinessYear[], periodStartDate: Date): BexioBusinessYear | undefined => {
    const index = getIndexBexioBusinessYear(businessYears, periodStartDate);
    if (index < 0) {
      const endDate = new Date(businessYears[businessYears.length - 1].end);
      endDate.setHours(0);
      if (periodStartDate.getTime() > endDate.getTime()) {
        return businessYears[businessYears.length - 1];
      }
    }
    return index > 0 ? businessYears[index - 1] : undefined;
  }
);

export const selectYearMonthSpan = createSelector(
  getSpanState,
  (state: SpanState) => {
    const startDate = new Date(state.startYear, state.startMonth);
    const endDate = new Date(state.endYear ?? state.startYear, state.endMonth);
    return getYearMonths(startDate, endDate)
  }
);

export const selectPrevYearMonthSpan = createSelector(
  selectPrevBusinessYear,
  (businessYear: BexioBusinessYear | undefined) => {
    if (businessYear) {
      const startDate = new Date(businessYear.start);
      const endDate = new Date(businessYear.end);
      return getYearMonths(startDate, endDate)
    }
    return [];
  }
);

export const selectFirstDayOfCurrentBusinessYear = createSelector(
  selectCurrentBusinessYear,
  (businessYear: BexioBusinessYear | undefined): Date | undefined => {
    if (businessYear) {
      const startDate = new Date(businessYear.start);
      startDate.setHours(0);
      return startDate;
    }
    return undefined;
  }
);
