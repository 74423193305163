import { State } from '../../core/reducers';
import { createSelector } from '@ngrx/store';
import ProfileState from './profile.reducer';

export const selectApp = (state: State) => state.paymentState;

export const selectHasAccessToPaidFeatures = createSelector(
  selectApp,
  (state: ProfileState) => state.accessToPaidFeatures
);

export const selectIsCompanySubscriptionValid = createSelector(
  selectApp,
  (state: ProfileState) => state.companySubscriptionValid
);

export const selectUserProfile = createSelector(
  selectApp,
  (state: ProfileState) => state.userProfile
);

export const selectSettings = createSelector(
  selectApp,
  (state: ProfileState) => state.userSettings
);

export const selectPreferredLanguage = createSelector(
  selectSettings,
  (settings) => settings.preferredLanguage
);

