import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { captureException } from '@sentry/angular-ivy';
import { selectConnectedUser } from '../../authentication/store/authentication.selector';
import { State } from '../../core/reducers';
import { CaptureContext } from '@sentry/types';
import { NotificationService } from '../service/notification.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserReportService } from '../service/user-report.service';

@Injectable({
  providedIn: 'root'
})
export class RestApiErrorInterceptor implements HttpInterceptor {
  constructor(
    private store: Store<State>,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
    private userReportService: UserReportService,
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      catchError(async error => {
        if (error.status !== 401 && error.status !== 403) {
          const user = await firstValueFrom(this.store.select(selectConnectedUser));
          const context: CaptureContext = {
            user: {
              id: user?.id
            }
          };
          captureException(error, context);
          const locale = this.translocoService.getActiveLang();
          const supportUrl = this.userReportService.getSupportUrl(locale);
          const message = this.translocoService.translate('errors.unknown', { supportUrl });
          this.notificationService.flashWarning(message);
        }
        throw error;
      }),
    );
  }

}
