import { BexioAccountGroup } from './bexio.model';
import { FlowTableLevel, FlowTableRow, ROW_TYPE } from './flow-table-row.model';
import { sum } from '../../shared/utils/numbers';

const cashOutAccounts = [4, 5, 6, 7, 8];
export const isCashInAccount = (accountNum: string): boolean => Number(accountNum.charAt(0)) === 3;

export const isCashOutAccount = (accountNum: string): boolean =>
  cashOutAccounts.includes(Number(accountNum.charAt(0)));

export const operatingAccounts = [3, 4, 5, 6, 7, 8];
export const investingAccounts = [1]
export const financingAccounts = [2];
export const assetsAccounts = [1];
export const foreignCapitalAccounts = [20, 24];
export const equityAccounts = [28];
export const investingOrFinancingAccounts = [...investingAccounts, ...financingAccounts];
export const includedInvestingAndFinancingAccounts = [150, 160, 170, 210, 220, 240, 250];

export const TREASURY_ACCOUNT_GROUP = '100';
export const INITIAL_TREASURY_ACCOUNT = '9100';

export const shouldExcludeInvestingOrFinancingAccount = (accountNo: number): boolean => {
  const firstDigit = Number(accountNo.toString().charAt(0));
  return investingOrFinancingAccounts.includes(firstDigit) && !includedInvestingAndFinancingAccounts.includes(accountNo);
}

export enum AccountCategory {
  operating = 'operating',
  investing = 'investing',
  financing = 'financing',
  treasury = 'treasury',
}

export enum AccountType {
  input = 'input',
  output = 'output',
  both = 'both',
  variation = 'variation',
}

export const ACCOUNTING_MODE = {
  operating: 'operating',
  investing: 'investing',
  financing: 'financing',
} as const;
export type AccountingMode = typeof ACCOUNTING_MODE[keyof typeof ACCOUNTING_MODE];

export interface AccountInfo {
  number: number;
  name: string;
  cat: AccountCategory;
  type: AccountType;
}

export interface AccountValue {
  account_no: string;
  value: number;
}

export interface MonthlyAccount {
    yearMonth: string;
    accountValue: AccountValue;
}

export interface MonthlyAccounts {
  yearMonth: string;
  accountsValues: AccountValue[];
}

export interface AccountNode {
  info: AccountInfo;
  children: AccountNode[];
}

export const getCategory = (accountNo: number): AccountCategory => {
  const firstDigit = Number(accountNo.toString().charAt(0));
  if (operatingAccounts.includes(firstDigit)) {
    return AccountCategory.operating;
  } else if (investingAccounts.includes(firstDigit)) {
    return AccountCategory.investing;
  } else if (financingAccounts.includes(firstDigit)) {
    return AccountCategory.financing;
  } else {
    return AccountCategory.treasury;
  }
}

export const getIO = (accountNumber: number): AccountType => {
  const firstDigit = Number(accountNumber.toString().charAt(0));
  switch (firstDigit) {
    case 3:
      return AccountType.input;
    case 4:
    case 5:
    case 6:
      return AccountType.output;
    case 1:
    case 2:
    case 7:
    case 8:
      return AccountType.both;
    default:
      return AccountType.variation;
  }
};

export const getParentAccountGroupsIds = (parentId: number, accountGroups: BexioAccountGroup[]): number[] => {
  const parent = accountGroups.find(group => group.id === parentId);
  if (parent) {
    if (parent.parent_fibu_account_group_id != null) {
      return [Number(parent.account_no), ...getParentAccountGroupsIds(parent.parent_fibu_account_group_id, accountGroups)];
    } else {
      return [Number(parent.account_no)];
    }
  }
  return [];
}

export const getLevel = (depth: number): FlowTableLevel => Object.values(FlowTableLevel)[depth];

export const createRowNode = (accountNode: AccountNode, depth: number, monthlyAccounts: MonthlyAccounts[], openedAccounts: number[]): FlowTableRow => {
  const info = accountNode.info;
  if (accountNode.children.length === 0) {
    let values: number[] = Array(monthlyAccounts.length).fill(0);
    if (accountNode.info.number >= 1000) {
      values = monthlyAccounts.map(accounts => accounts.accountsValues.find(account => account.account_no === accountNode.info.number.toString())?.value ?? 0)
    }
    return createTableRow(
      info.name,
      info.number,
      info.cat,
      getIO(info.number),
      getLevel(info.number.toString().length - 1),
      values,
      [],
      depth,
      info.number < 1 || openedAccounts.includes(info.number)
    )
  } else {
    const children = accountNode.children.map(node => createRowNode(node, depth + 1, monthlyAccounts, openedAccounts));
    // TODO here values have to be added or subtracted properly when reaching depth = 0
    const values = children.reduce((acc: number[], cur) => {
      const sign = (depth > 1 || isCashInAccount(cur.accountNo.toString()) ? 1 : -1);
      return cur.values.map((value, index) => acc[index] + sign * value);
    }, Array(monthlyAccounts.length).fill(0));
    return createTableRow(
      info.name,
      info.number,
      info.cat,
      getIO(info.number),
      getLevel(info.number.toString().length - 1),
      values,
      children,
      depth,
      info.number < 1 || openedAccounts.includes(info.number)
    )
  }
}

const createTableRow = (
  name: string,
  accountNo: number,
  cat: AccountCategory,
  type: AccountType,
  level: FlowTableLevel,
  values: number[],
  children: FlowTableRow[],
  depth: number,
  opened: boolean
) => ({
  name,
  accountNo,
  cat,
  type,
  level,
  opened,
  color: null,
  values: values,
  total: sum(values),
  children,
  depth,
  rowType: depth === 1 ? ROW_TYPE.heading : ROW_TYPE.node
});
