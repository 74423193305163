import { User } from '../model/user.model';
import { createReducer, on } from '@ngrx/store';
import { getConnectedUserSuccess, userSignInWithBexioSuccess, userSignOutSuccess } from './authentication.action';

export default interface AuthenticationState {
  user: User | undefined;
}

export const initialState: AuthenticationState = {
  user: undefined,
};

export const reducer = createReducer(
  initialState,
  on(userSignOutSuccess, (): AuthenticationState => initialState),
  on(getConnectedUserSuccess, userSignInWithBexioSuccess, (state, action): AuthenticationState => ({
    ...state,
    user: action.user
  })),
);
