import { createReducer, on } from '@ngrx/store';
import { userSignOutSuccess } from '../../authentication/store/authentication.action';
import {
  getUserProfileSuccess,
  checkAccessToPaidFeaturesSuccess,
  checkCompanySubscriptionSuccess,
  settingsChangedSuccess
} from './profile.actions';
import { Profile, Settings } from '../model/profile.model';

export default interface ProfileState {
  companySubscriptionValid: boolean | null;
  accessToPaidFeatures: boolean | null;
  userProfile: Profile | null;
  userSettings: Settings;
}

export const initialState: ProfileState = {
  companySubscriptionValid: null,
  accessToPaidFeatures: null,
  userProfile: null,
  userSettings: {
    preferredLanguage: 'en',
    defaultScenario: null,
    displayOnlyFilledRow: false,
    displayIntermediaryAccountGroups: false,
  } as Settings
};

export const reducer = createReducer(
  initialState,
  on(userSignOutSuccess, (): ProfileState => initialState),
  on(checkCompanySubscriptionSuccess, (state, { companySubscriptionValid }): ProfileState => ({
    ...state,
    companySubscriptionValid
  })),
  on(checkAccessToPaidFeaturesSuccess, (state, { accessToPaidFeatures }): ProfileState => ({
    ...state,
    accessToPaidFeatures
  })),
  on(getUserProfileSuccess, (state, { userProfile }): ProfileState => ({
    ...state,
    userProfile,
    userSettings: userProfile.settings
  })),
  on(settingsChangedSuccess, (state, { userSettings }): ProfileState => ({
    ...state,
    userSettings: {...userSettings,
      preferredLanguage: userSettings.preferredLanguage ?? state.userSettings.preferredLanguage,
      defaultScenario: userSettings.defaultScenario ?? state.userSettings.defaultScenario,
      displayOnlyFilledRow: userSettings.displayOnlyFilledRow ?? state.userSettings.displayOnlyFilledRow,
      displayIntermediaryAccountGroups: userSettings.displayIntermediaryAccountGroups ?? state.userSettings.displayIntermediaryAccountGroups,
    }
  })),
);
