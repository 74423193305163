import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { FormControl } from '@angular/forms';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { YearMonth } from '../../model/period.model';
import { DateAdapter } from '@angular/material/core';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Subscription } from 'rxjs';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent implements OnInit, OnDestroy {

  rawStartYearMonth = '2022-01';
  _startYearMonth = moment(this.rawStartYearMonth);
  @Input() set startYearMonth(value: string) {
    const temp = moment(value);
    this._startYearMonth.set('year', temp.year());
    this._startYearMonth.set('month', temp.month());
    this.startDateControl.patchValue(this._startYearMonth);
    this.rawStartYearMonth = `${temp.year()}-${temp.month()+1}`;
  }

  rawEndYearMonth = '2022-12';
  _endYearMonth = moment(this.rawEndYearMonth);
  @Input() set endYearMonth(value: string) {
    const temp = moment(value);
    this._endYearMonth.set('year', temp.year());
    this._endYearMonth.set('month', temp.month());
    this.endDateControl.patchValue(this._endYearMonth);
    this.rawEndYearMonth = `${temp.year()}-${temp.month()+1}`;
  }

  @Output() startYearMonthChange = new EventEmitter<YearMonth>();
  @Output() endYearMonthChange = new EventEmitter<YearMonth>();

  startDateControl = new FormControl<Moment>(this._startYearMonth);
  endDateControl = new FormControl<Moment>(this._endYearMonth);
  locale$ = this.localeService.localeChanges$;
  localeSubscription = new Subscription();

  constructor(
    private localeService: TranslocoLocaleService,
    private dateAdapter: DateAdapter<Moment>
  ) {
  }

  ngOnDestroy(): void {
    this.localeSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.localeSubscription.add(this.locale$.subscribe(locale => {
      this.dateAdapter.setLocale(locale);
    }));
  }

  setStartDate(normalizedMonthAndYear: Moment, datePicker: MatDatepicker<any>) {
    datePicker.select(normalizedMonthAndYear);
    datePicker.close();
    const startYearMonth: YearMonth = {
      year: this.startDateControl.value!.year(),
      month: this.startDateControl.value!.month()
    }
    this.startYearMonthChange.emit(startYearMonth);
  }

  setEndDate(normalizedMonthAndYear: Moment, datePicker: MatDatepicker<any>) {
    datePicker.select(normalizedMonthAndYear);
    datePicker.close();
    const endYearMonth: YearMonth = {
      year: this.endDateControl.value!.year(),
      month: this.endDateControl.value!.month()
    }
    this.endYearMonthChange.emit(endYearMonth);
  }
}
