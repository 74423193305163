import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricingComponent } from './page/pricing/pricing.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FooterComponent } from '../dashboard/component/footer/footer.component';


@NgModule({
  declarations: [
    PricingComponent
  ],
  imports: [
    CommonModule,
    TranslocoModule,
    MatButtonModule,
    RouterLink,
    MatButtonToggleModule,
    FooterComponent,
  ],
})
export class ProfileModule { }
