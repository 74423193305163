import { Component, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { settingsChanged } from '../../profile/store/profile.actions';


@Component({
  selector: 'app-lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LangSelectorComponent {

  selectedLanguage$ = this.translocoService.langChanges$;

  constructor(
    private translocoService: TranslocoService,
    private store: Store,
  ) {
  }

  languageChanged(preferredLanguage: string): void {
    if (preferredLanguage !== this.translocoService.getActiveLang()) {
      this.translocoService.setActiveLang(preferredLanguage);
      this.store.dispatch(settingsChanged({ userSettings: { preferredLanguage } }));
    }
  }
}
