import { HttpClient } from '@angular/common/http';
import { provideTransloco, Translation, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { provideTranslocoMessageformat } from '@ngneat/transloco-messageformat';
import { provideTranslocoLocale, TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { AvailableLanguage } from './available-lang';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {
  }

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule, TranslocoLocaleModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: Object.keys(AvailableLanguage),
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
    provideTranslocoLocale({
      // eslint-disable-next-line
      langToLocaleMapping: Object.keys(AvailableLanguage).reduce((acc: any, lang: string) => {
        // eslint-disable-next-line
        acc[lang] = lang + '-CH';
        // eslint-disable-next-line
        return acc;
      }, {}),
    }),
    provideTranslocoMessageformat()
  ],
})
export class TranslocoRootModule {
}
