import { Injectable } from '@angular/core';
import {
  BexioAccount,
  BexioAccountGroup,
  BexioEntry,
  ParsedBexioEntry,
  YearMonthEntries
} from '../model/bexio.model';
import {
  AccountCategory,
  AccountInfo,
  AccountNode,
  AccountType,
  AccountValue,
  getCategory,
  getIO,
  getParentAccountGroupsIds,
  isCashInAccount,
  MonthlyAccounts,
  shouldExcludeInvestingOrFinancingAccount,
} from '../model/accounting.model';
import { buildYearMonthString } from '../model/period.model';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  constructor() {
  }

  processEntries = (accounts: BexioAccount[], currencyRates: Map<number, number>, entries: YearMonthEntries[]): MonthlyAccounts[] =>
    entries.map(yearMonthEntries => {
      const yearMonth = buildYearMonthString(yearMonthEntries.yearMonth.year, yearMonthEntries.yearMonth.month);
      const accountsValues = this.processMonthlyEntries(accounts, currencyRates, yearMonthEntries.entries);
      return { yearMonth, accountsValues } as MonthlyAccounts
    });

  buildAccountHierarchy = (accountGroups: BexioAccountGroup[], accounts: BexioAccount[]): AccountNode => {
    const rootNode = accounts.reduce((rootNode: AccountNode, currentAccount: BexioAccount) => {
      const hierarchy = getParentAccountGroupsIds(currentAccount.fibu_account_group_id, accountGroups);
      if (shouldExcludeInvestingOrFinancingAccount(hierarchy[0])) {
        return rootNode;
      }
      let parent = rootNode;
      while (hierarchy.length > 0) {
        const childAccountNumber = hierarchy.pop()!;
        let child = parent.children.find(accountGroup => accountGroup.info.number === childAccountNumber)
        if (!child) {
          const childAccount = accountGroups.find(account => Number(account.account_no) === childAccountNumber);
          child = {
            info: {
              number: childAccountNumber,
              name: childAccount?.name ?? 'name-unknown',
              cat: getCategory(childAccountNumber),
              type: getIO(childAccountNumber)
            } as AccountInfo,
            children: []
          } as AccountNode;
          parent.children.push(child);
          parent.children.sort((a, b) => a.info.number - b.info.number);
        }
        parent = child;
      }
      const childAccountNumber = Number(currentAccount.account_no);
      parent.children.push({
        info: {
          number: childAccountNumber,
          name: currentAccount.name,
          cat: getCategory(childAccountNumber),
          type: getIO(childAccountNumber)
        } as AccountInfo,
        children: []
      } as AccountNode);
      parent.children.sort((a, b) => a.info.number - b.info.number);
      return rootNode;
    },
    {
      info: {
        number: 0,
        name: 'root',
        cat: AccountCategory.operating,
        type: AccountType.input
      } as AccountInfo,
      children: []
    } as AccountNode);
    const operatingSubRootNode = this.createSubRootNode('activity-operating', AccountCategory.operating, rootNode.children);
    const investingSubRootNode = this.createSubRootNode('activity-investing', AccountCategory.investing, rootNode.children);
    const financingSubRootNode = this.createSubRootNode('activity-financing', AccountCategory.financing, rootNode.children);
    return {
      ...rootNode,
      children: [operatingSubRootNode, investingSubRootNode, financingSubRootNode]
    };
  };

  private processMonthlyEntries = (accounts: BexioAccount[], currencyRates: Map<number, number>, entries: ParsedBexioEntry[]): AccountValue[] => {
    return accounts.map(({ id, account_no }) => {
      const credit = this.amountSum(
        entries.filter(d => d.credit_account_id === id),
        currencyRates
      );
      const debit = this.amountSum(
        entries.filter(d => d.debit_account_id === id),
        currencyRates
      );
      const value = isCashInAccount(account_no) ? credit - debit : debit - credit; // TODO is that true?
      return {
        account_no,
        value
      };
    }).sort((a, b) => a.account_no.localeCompare(b.account_no));
  }

  private amountSum = (entries: BexioEntry[], currencyRates: Map<number, number>): number =>
    entries.reduce((acc, curr) => {
      const rate = currencyRates.get(curr.currency_id) ?? 1;
      const amount = curr.amount * rate;
      return acc + amount;
    }, 0);

  private createSubRootNode = (name: string, cat: AccountCategory, children: AccountNode[]) => ({
    info: {
      number: 0,
      name,
      cat,
      type: AccountType.both,
      opened: true
    } as AccountInfo,
    children: children.filter(node => node.info.cat === cat)
      .sort((a, b) => a.info.number - b.info.number)
  } as AccountNode);
}
