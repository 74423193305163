import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoading } from '../store/app.selector';

@Component({
  selector: 'app-loading',
  template: `
    <mat-progress-bar *ngIf="loading$ | async" mode="query"></mat-progress-bar>
  `,
  styles: [`
    mat-progress-bar {
      z-index: 10;
    }
  `],
})
export class LoadingComponent {

  loading$ = this.store.select(selectLoading);

  constructor(
    private store: Store<any>,
  ) {
  }
}
