import { Injectable } from '@angular/core';
import { AccessInfo } from '../../authentication/model/access-info.model';

const IDP_INFO = 'idp-info';
const REFERRAL = 'referral';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  storeReferralCode = (referral: string) => localStorage.setItem(REFERRAL, referral );
  getReferralCode = (): string | undefined => localStorage.getItem(REFERRAL) ?? undefined;
  destroyReferralCode = () => localStorage.removeItem(REFERRAL);

  storeAccessToken = (accessToken: string) => localStorage.setItem(IDP_INFO, JSON.stringify({ access_token: accessToken }));
  getAccessToken = (): string | undefined => this.getIdpInfo()?.access_token;
  hasIdfInfo = (): boolean => !!localStorage.getItem(IDP_INFO);
  getIdpInfo = (): AccessInfo | undefined => {
    const rawData = localStorage.getItem(IDP_INFO) as string;
    return JSON.parse(rawData) ?? undefined;
  }
  destroyIdpInfo = () => localStorage.removeItem(IDP_INFO);

  getCompanyId = (): string | undefined => {
    const bexioJwt = this.getAccessToken();
    if (bexioJwt) {
      const jwtData = JSON.parse(atob(bexioJwt.split('.')[1])) ?? {};
      return jwtData['company_id'];
    } else {
      return undefined;
    }
  }

}
