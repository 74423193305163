import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, map, of, switchMap, tap } from 'rxjs';
import { ProfileService } from '../service/profile.service';
import {
  checkAccessToPaidFeatures,
  checkAccessToPaidFeaturesError,
  checkAccessToPaidFeaturesSuccess,
  checkCompanySubscription,
  checkCompanySubscriptionError,
  checkCompanySubscriptionSuccess,
  createCheckoutSession,
  createCheckoutSessionError,
  createCheckoutSessionSuccess,
  getUserProfileSuccess,
  settingsChanged,
  settingsChangedError,
  settingsChangedSuccess
} from './profile.actions';
import { getConnectedUserSuccess, userSignInWithBexioSuccess } from '../../authentication/store/authentication.action';
import { hideLoading, showLoading } from '../../core/store/app.actions';
import { selectIsUserConnected } from '../../authentication/store/authentication.selector';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { catchError } from 'rxjs/operators';
import { LocalStorageService } from '../../home/services/local-storage.service';

@Injectable()
export class ProfileEffects {

  constructor(
    private actions$: Actions,
    private store: Store<any>,
    private profileService: ProfileService,
    private translocoService: TranslocoService,
    private localStorageService: LocalStorageService,
  ) {
  }

  profileLoadingEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        checkCompanySubscription,
        checkAccessToPaidFeatures,
        createCheckoutSession,
      ),
      map(() => showLoading())
    );
  });

  profileLoadedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        checkCompanySubscriptionSuccess,
        checkCompanySubscriptionError,
        checkAccessToPaidFeaturesSuccess,
        checkAccessToPaidFeaturesError,
        createCheckoutSessionSuccess,
        createCheckoutSessionError,
      ),
      map(() => hideLoading())
    );
  });

  checkSubscriptionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getConnectedUserSuccess),
      map(checkCompanySubscription)
    )
  });

  checkAccessToPaidFeaturesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getConnectedUserSuccess),
      map(checkAccessToPaidFeatures)
    )
  });

  isCompanySubscriptionValidEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkCompanySubscription),
      switchMap(() => this.profileService.isCompanySubscriptionValid().pipe(
        map(companySubscriptionValid => checkCompanySubscriptionSuccess({ companySubscriptionValid })),
        catchError((error) => of(checkCompanySubscriptionError({ error })))
      ))
    );
  });

  hasAccessToPaidFeaturesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkAccessToPaidFeatures),
      switchMap(() => this.profileService.hasAccessToPaidFeatures().pipe(
        map(accessToPaidFeatures => checkAccessToPaidFeaturesSuccess({ accessToPaidFeatures })),
        catchError((error) => of(checkAccessToPaidFeaturesError({ error })))
      ))
    );
  });

  createCheckoutSessionEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createCheckoutSession),
      switchMap(({ monthly }) => this.profileService.getCheckoutSessionURL(monthly).pipe(
        tap((checkoutSessionURL) => window.location.href = checkoutSessionURL),
        map(() => createCheckoutSessionSuccess()),
        catchError((error) => of(createCheckoutSessionError({ error })))
      ))
    );
  });

  saveReferralCodeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSignInWithBexioSuccess),
      map(() => this.localStorageService.getReferralCode()),
      filter((referral) => !!referral),
      switchMap((referral) => this.profileService.saveReferralCode(referral!)),
      filter((saved) => saved),
      tap(() => this.localStorageService.destroyReferralCode())
    );
  }, { dispatch: false });

  saveLanguageAtLoginEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSignInWithBexioSuccess),
      map(() => this.translocoService.getActiveLang()),
      filter(language => language != 'en'),
      switchMap(preferredLanguage => this.profileService.saveUserSettings({ preferredLanguage }).pipe(
        map(() => settingsChangedSuccess({ userSettings: { preferredLanguage } }))
      ))
    );
  });

  getProfileEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getConnectedUserSuccess),
      switchMap(() => {
        return this.profileService.getUserProfile().pipe(
          map(userProfile => getUserProfileSuccess({ userProfile }))
        );
      })
    );
  });

  getSavedLanguageEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getUserProfileSuccess),
      filter(({ userProfile }) => !!userProfile?.settings?.preferredLanguage),
      map(({ userProfile }) => userProfile.settings.preferredLanguage),
      tap(preferredLanguage => this.translocoService.setActiveLang(preferredLanguage)),
      map(preferredLanguage => settingsChangedSuccess({ userSettings: { preferredLanguage } })),
    );
  });

  saveLanguageEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(settingsChanged),
      concatLatestFrom(() => [
        this.store.select(selectIsUserConnected)
      ]),
      filter(([, isUserConnected]) => isUserConnected),
      switchMap(([{ userSettings },]) => this.profileService.saveUserSettings(userSettings).pipe(
        map(() => settingsChangedSuccess({ userSettings })),
        catchError((error) => of(settingsChangedError({ error })))
      ))
    );
  });
}
