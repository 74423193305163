export default {
  intrinsic: {
    de: ``,
    en: ``,
    fr: ``,
    it: ``,
  },
  performance: {
    de: `<p><strong>Die Bewertung Ihres Unternehmens wurde nach der Return-Value-Methode berechnet. </strong></p>
        <p>Sie besteht darin, den Wert eines Unternehmens auf der Grundlage zukünftiger Gewinne mit einer angepassten Kapitalisierungsrate zu berechnen.</p>
        <p>Nach der Return-Value-Methode wird ein Unternehmen als Investition betrachtet. Die Aufmerksamkeit richtet sich daher ausschließlich auf die zukünftigen Gewinne, die das Unternehmen generieren wird, auf die damit verbundenen Risiken oder auf die Renditeprognosen. Das Betriebsvermögen wird nur als Mittel zur Gewinnerzielung betrachtet und ihm wird kein eigener Wert zugeschrieben.</p>
        <p>Die angepassten Betriebsgewinne werden unter Verwendung einer Kapitalisierungsrate abgezinst, die einer angepassten Renditeprognose für dieses spezifische Unternehmen entspricht.</p>
        <p>Wenn das Unternehmen nicht wesentliche Vermögenswerte hat (z.B. externe Gebäude oder übermäßige Liquidität), wird es separat bewertet und dann zum berechneten Return-Value hinzugefügt.</p>
        <p>Im Allgemeinen basiert die Bewertung zukünftiger Erträge oft auf dem <strong>angepassten durchschnittlichen Betriebsergebnis der letzten 3 Jahre</strong>. Bei ihrer Berechnung ist es wichtig zu beachten, dass es sich um angepasste Gewinne handelt. Außergewöhnliche und nicht betriebliche Einnahmen und Ausgaben sowie Gehälter, die nicht marktgerecht sind, müssen korrigiert werden.</p>
        <p>Die <strong>Kapitalisierungsrate</strong> wird wie folgt berechnet, wobei die entsprechenden Zahlen je nach Größe, Branche und individuellen Umständen des Unternehmens variieren können:</p>
        <ul>
        <li data-list="bullet">Risikofreier Zinssatz</li>
        <li data-list="bullet">Marktrisikoprämie</li>
        <li data-list="bullet">Satz für Kleinunternehmen</li>
        <li data-list="bullet">Satz für Nicht-Liquidität</li>
        <li data-list="bullet">Satz für spezifisches Unternehmensrisiko: Fallweise</li>
        </ul>
        <p>Die Berechnung der Kapitalisierungsrate, insbesondere im Bereich spezifischer Risiken für das Unternehmen, erfordert eine subjektive Bewertung mehrerer Faktoren wie Marktposition, Kundenrisiko, Lieferantenrisiko, persönliches Risiko usw.</p>
        <p>Da bereits die geringste Veränderung der Kapitalisierungsrate den Return-Value stark beeinflussen kann, ist es nicht überraschend, dass dieser Punkt Gegenstand vieler Debatten ist.</p>`,
    en: `<p><strong>The valuation of your company has been calculated according to the return value method. </strong></p>
        <p>It consists of calculating the value of a company based on future profits with an adjusted capitalization rate.</p>
        <p>Under the return value method, a company is considered an investment. Attention is therefore focused solely on the future profits that the company will generate, on the associated risks or on the return forecasts. The operating asset is only considered as a means of generating profits and no own value is attributed to it.</p>
        <p>The adjusted operating profits are discounted using a capitalization rate corresponding to an adapted return forecast for this specific company.</p>
        <p>If the company has non-essential assets (e.g. external buildings or excessive liquidity), it will be valued separately and then added to the calculated return value.</p>
        <p>In general, the evaluation of future returns is often based on the <strong>adjusted average operating profit for the last 3 years</strong>. At the time of their calculation, it is important to note that these are adjusted profits. Extraordinary and non-operating income and expenses, as well as salaries not in line with the market, must be corrected.</p>
        <p><strong>The capitalization rate</strong> is calculated as follows, knowing that the corresponding figures may vary depending on the size, sector and individual circumstances of the company:</p>
        <ul>
        <li data-list="bullet">Risk-free interest rate</li>
        <li data-list="bullet">Market risk premium</li>
        <li data-list="bullet">Rate for small businesses</li>
        <li data-list="bullet">Rate for non-liquidity</li>
        <li data-list="bullet">Rate for specific risk to the company: case by case</li>
        </ul>
        <p>Calculating the capitalization rate, particularly in the area of specific risks to the company, requires a subjective assessment of several factors such as the market position, customer risk, supplier risk, personal risk, etc.</p>
        <p>Given that even the smallest change in the capitalization rate can greatly influence the return value, it is not surprising that this point is the subject of many debates.</p>`,
    fr: `<p><strong>La valorisation de votre entreprise a &eacute;t&eacute; calcul&eacute;e selon m&eacute;thode de la valeur de rendement. </strong></p>
        <p>Elle consiste &agrave; calculer la valeur d&rsquo;une entreprise sur la base des b&eacute;n&eacute;fices futurs avec un taux de capitalisation adapt&eacute;.</p>
        <p>Dans le cadre de la m&eacute;thode de valeur de rendement, on consid&egrave;re une entreprise comme un investissement. L&rsquo;attention se concentre donc uniquement sur les b&eacute;n&eacute;fices futurs que la soci&eacute;t&eacute; r&eacute;alisera, sur les risques associ&eacute;s ou sur les pr&eacute;visions de rendement. L&rsquo;actif d&rsquo;exploitation n&rsquo;est consid&eacute;r&eacute; que comme un moyen de r&eacute;aliser des b&eacute;n&eacute;fices et aucune valeur propre ne lui est attribu&eacute;e.</p>
        <p>Les b&eacute;n&eacute;fices d&rsquo;exploitation ajust&eacute;s sont escompt&eacute;s &agrave; l&rsquo;aide d&rsquo;un taux de capitalisation correspondant &agrave; une pr&eacute;vision de rendement adapt&eacute;e au risque de cette entreprise sp&eacute;cifique.</p>
        <p>Si l&rsquo;entreprise dispose d&rsquo;actif non indispensable &agrave; l&rsquo;exploitation (par ex. des immeubles externes &agrave; l&rsquo;entreprise ou des liquidit&eacute;s exc&eacute;dentaires), celui-ci sera &eacute;valu&eacute; s&eacute;par&eacute;ment, puis additionn&eacute; &agrave; la valeur de rendement calcul&eacute;e.</p>
        <p>En r&egrave;gle g&eacute;n&eacute;rale, l'&eacute;valuation des futurs rendements se fonde souvent sur le <strong>b&eacute;n&eacute;fice d'exploitation moyen ajust&eacute; des 3 derni&egrave;res ann&eacute;es</strong>. Au moment de leur calcul, il est important de souligner qu&rsquo;il s&rsquo;agit ici de b&eacute;n&eacute;fices ajust&eacute;s. Les produits et charges extraordinaires et hors exploitation, ainsi que les salaires non conformes au march&eacute; doivent &ecirc;tre corrig&eacute;s.</p>
        <p><strong>Le taux de capitalisation </strong>se calcule comme suit, sachant que les chiffres correspondants peuvent varier en fonction de la taille, du secteur et des circonstances individuelles de l&rsquo;entreprise:</p>
        <ul>
        <li data-list="bullet">Taux d&rsquo;int&eacute;r&ecirc;t sans risque</li>
        <li data-list="bullet">Prime de risque du march&eacute;</li>
        <li data-list="bullet">Taux pour les petites entreprises</li>
        <li data-list="bullet">Taux pour non-liquidit&eacute;</li>
        <li data-list="bullet">Taux pour risque sp&eacute;cifique &agrave; l&lsquo;entreprise: au cas par cas</li>
        </ul>
        <p>Le calcul du taux de capitalisation, notamment dans le domaine des risques sp&eacute;cifiques &agrave; l&rsquo;entreprise, n&eacute;cessite une &eacute;valuation subjective de plusieurs facteurs tels que la position sur le march&eacute;, le risque clients, le risque fournisseurs, les risques personnels, etc.</p>
        <p>Compte tenu du fait que m&ecirc;me le plus petit des changements apport&eacute;s au taux de capitalisation peut grandement influencer la valeur de rendement, il n&rsquo;est pas surprenant que ce point fasse l&rsquo;objet de nombreux d&eacute;bats.</p>`,
    it: `<p><strong>La valutazione della tua azienda è stata calcolata secondo il metodo del valore di rendimento.</strong></p>
        <p>Consiste nel calcolare il valore di un'azienda in base ai profitti futuri con un tasso di capitalizzazione adeguato.</p>
        <p>Nel metodo del valore di rendimento, un'azienda è considerata un investimento. L'attenzione è quindi focalizzata esclusivamente sui profitti futuri che l'azienda genererà, sui rischi associati o sulle previsioni di rendimento. L'attivo operativo è considerato solo come un mezzo per generare profitti e non viene attribuito alcun valore proprio ad esso.</p>
        <p>I profitti operativi adeguati vengono scontati utilizzando un tasso di capitalizzazione corrispondente a una previsione di rendimento adattata per questa specifica azienda.</p>
        <p>Se l'azienda possiede beni non essenziali (ad esempio, edifici esterni o liquidità eccessiva), verrà valutata separatamente e poi aggiunta al valore di rendimento calcolato.</p>
        <p>In generale, la valutazione dei rendimenti futuri si basa spesso sul <strong>profitto operativo medio adeguato degli ultimi 3 anni</strong>. Al momento del calcolo, è importante notare che si tratta di profitti adeguati. Entrate e spese straordinarie e non operative, così come stipendi non in linea con il mercato, devono essere corretti.</p>
        <p><strong>Il tasso di capitalizzazione</strong> viene calcolato nel seguente modo, sapendo che le cifre corrispondenti possono variare a seconda della dimensione, del settore e delle circostanze individuali dell'azienda:</p>
        <ul>
        <li data-list="bullet">Tasso di interesse privo di rischio</li>
        <li data-list="bullet">Premio di rischio di mercato</li>
        <li data-list="bullet">Tasso per le piccole imprese</li>
        <li data-list="bullet">Tasso per la non liquidità</li>
        <li data-list="bullet">Tasso per il rischio specifico dell'azienda: caso per caso</li>
        </ul>
        <p>Calcolare il tasso di capitalizzazione, in particolare nell'ambito dei rischi specifici dell'azienda, richiede una valutazione soggettiva di diversi fattori come la posizione sul mercato, il rischio dei clienti, il rischio dei fornitori, il rischio personale, ecc.</p>
        <p>Dato che anche la più piccola variazione del tasso di capitalizzazione può influenzare notevolmente il valore di rendimento, non sorprende che questo punto sia oggetto di molti dibattiti.</p>`
  },
  practitioners: {
    de: ``,
    en: ``,
    fr: ``,
    it: ``,
  },
}
