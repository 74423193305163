import { Component, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { UserReportService } from '../../../shared/service/user-report.service';
import { selectCompanyInfo } from '../../store/accounting.selector';
import { selectRouteUrl } from '../../../core/router/router-store.selector';
import { Store } from '@ngrx/store';
import { State } from '../../../core/reducers';
import { TranslocoDirective } from '@ngneat/transloco';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatIconModule,
    NgClass,
    NgIf,
    RouterLink,
    TranslocoDirective,
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit, OnDestroy {
  features = environment.features;
  at = faEnvelope;
  supportUrl: string = '';
  supportSubscription: Subscription = new Subscription();

  companyInfo$ = this.store.select(selectCompanyInfo);
  routeUrl$ = this.store.select(selectRouteUrl);

  constructor(
    private store: Store<State>,
    private translocoLocaleService: TranslocoLocaleService,
    private userReportService: UserReportService,
  ) {

  }

  ngOnInit(): void {
    this.supportSubscription.add(this.translocoLocaleService.localeChanges$.subscribe(locale => {
      this.supportUrl = this.userReportService.getSupportUrl(locale);
    }));
  }

  ngOnDestroy(): void {
    this.supportSubscription.unsubscribe();
  }
}
