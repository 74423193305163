<ng-container *transloco="let t; read: 'dialog.budget'">
  <div class="collapse-right-button">
    <mat-form-field appearance="outline" subscriptSizing="dynamic">
      <mat-label>{{ t('title') }}</mat-label>
      <mat-select [value]="selectedValue"
                  (selectionChange)="selectedIdChange.emit($event.source.value.id)"
                  [disabled]="scenarios.length === 0 || disabled">
        <mat-option *ngFor="let scenario of scenarios" [value]="scenario">{{scenario.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-icon-button
            [matMenuTriggerFor]="scenarioMenu"
            [disabled]="disabled"
            [attr.aria-label]="t('info')">
      <mat-icon>more_vert</mat-icon>
    </button>
  </div>
  <mat-menu #scenarioMenu="matMenu">
    <button mat-menu-item [disabled]="selectedValue === undefined" (click)="renameScenario()">
      <mat-icon>edit_note</mat-icon>
      <span>{{ t('rename') }}</span>
    </button>
    <button mat-menu-item [disabled]="selectedValue === undefined" (click)="deleteScenario()">
      <mat-icon>delete</mat-icon>
      <span>{{ t('delete') }}</span>
    </button>
    <button mat-menu-item [disabled]="selectedValue === undefined" (click)="duplicateScenario()">
      <mat-icon>content_copy</mat-icon>
      <span>{{ t('duplicate') }}</span>
    </button>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="createNewScenario()">
      <mat-icon>add</mat-icon>
      <span>{{ t('new') }}</span>
    </button>
  </mat-menu>
</ng-container>
