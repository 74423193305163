import { Pipe, PipeTransform } from '@angular/core';
import { Color } from '../model/color.model';

@Pipe({
  name: 'rgb'
})
export class RgbPipe implements PipeTransform {

  transform(value: Color, alpha: number | undefined = undefined): string | undefined {
    return alpha ? value.toRGBA(alpha) : value.toRGB();
  }

}
