export class Color {
  r: number;
  g: number;
  b: number;

  constructor(r: number, g: number, b: number) {
    this.r = r;
    this.g = g;
    this.b = b;
  }

  toRGB(): string {
    return `rgb(${this.r}, ${this.g}, ${this.b})`
  }

  toRGBA(alpha: number): string {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${alpha})`
  }

  static buildRed(): Color {
    return new Color(255, 0, 0);
  }

  static buildGreen(): Color {
    return new Color(0, 255, 0);
  }

  static buildBlue(): Color {
    return new Color(0, 0, 255);
  }
}
