import { selectAccounts, selectCurrencyMap } from './accounting.selector';
import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { filter, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BexioService } from '../service/bexio.service';
import { Store } from '@ngrx/store';
import { AccountingService } from '../service/accounting.service';
import { processedMonthlyEntriesIntoAccountStates } from './monthly-accounts.action';
import { getMissingEntries, getMonthlyEntries, getMonthlyEntriesError, getMonthlyEntriesSuccess, } from './monthly-entries.action';
import { getAccountHierarchySuccess } from './accounting.action';
import { selectMissingYearMonths } from './monthly-accounts.selector';
import { changeEndYearMonth, changeStartYearMonth } from './span.action';
import { selectMonths } from './monthly-entries.selector';
import { fromYearMonthString } from '../model/period.model';
import { selectBusinessYearsTotalSpan } from './span.selector';

@Injectable({
  providedIn: 'root',
})
export class MonthlyEffects {

  constructor(
    private actions$: Actions,
    private bexioService: BexioService,
    private accountingService: AccountingService,
    private store: Store<any>
  ) {
  }

  getEntriesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAccountHierarchySuccess),
      map(() => getMissingEntries())
    )
  });

  changePeriodEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMissingEntries, changeStartYearMonth, changeEndYearMonth),
      concatLatestFrom(() => this.store.select(selectMissingYearMonths)),
      filter(([, missingYearMonths]) => missingYearMonths.length > 0),
      map(([, missingYearMonths]) => getMonthlyEntries({
        yearMonths: missingYearMonths.map(yearMonth => {
          const year = Number(yearMonth.substring(0, 4));
          const month = Number(yearMonth.substring(5)) - 1;
          return { year, month };
        })
      }))
    )
  });

  getAllOtherEntriesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMonthlyEntriesSuccess),
      concatLatestFrom(() => [
        this.store.select(selectBusinessYearsTotalSpan),
        this.store.select(selectMonths),
      ]),
      map(([, allYearMonths, existingYearMonths]) => {
        const existingYearMonthsString = existingYearMonths.map(yearMonth => yearMonth.toString());
        return allYearMonths.filter(yearMonth => !existingYearMonthsString.includes(yearMonth));
      }),
      filter(missingYearMonths => missingYearMonths.length > 0),
      map(missingYearMonths => getMonthlyEntries({
          yearMonths: missingYearMonths.map(yearMonth => fromYearMonthString(yearMonth))
        })
      )
    );
  });

  getMonthlyEntriesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMonthlyEntries),
      mergeMap(({ yearMonths }) =>
        this.bexioService.getYearMonthEntries(yearMonths).pipe(
          map(entries => getMonthlyEntriesSuccess({ entries })),
          catchError((error) => of(getMonthlyEntriesError({ error })))
        ))
    );
  });

  processMonthlyEntriesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMonthlyEntriesSuccess),
      concatLatestFrom(() => [
        this.store.select(selectAccounts),
        this.store.select(selectCurrencyMap),
      ]),
      map(([{ entries }, accounts, currencyRates]) => processedMonthlyEntriesIntoAccountStates({
        monthlyAccountsList: this.accountingService.processEntries(accounts, currencyRates, entries)
      }))
    );
  });
}
