import { Action, createReducer, on } from '@ngrx/store';
import {
  BexioAccount,
  BexioAccountGroup,
  BexioCompanyInfo,
  BexioEntry,
  CurrencyExchangeFactor,
} from '../model/bexio.model';
import { userSignOutSuccess } from '../../authentication/store/authentication.action';
import {
  closeAllRows, displayIntermediaryAccountGroupsChanged,
  getAccountHierarchySuccess,
  getReferenceDataSuccess,
  getTreasuryEntriesForCurrentBusinessYearSuccess,
  openAllRows,
  openFirstLevel,
  setAccountingMode,
  setInitialTreasury,
  toggleOpenedStatus
} from './accounting.action';
import { ACCOUNTING_MODE, AccountingMode, AccountNode } from '../model/accounting.model';

export default interface AccountingState {
  accountHierarchy: AccountNode | null;
  openedAccounts: number[];
  companyInfo: BexioCompanyInfo | null;
  currencies: CurrencyExchangeFactor[];
  accountGroups: BexioAccountGroup[];
  accounts: BexioAccount[];
  accountingMode: AccountingMode;
  treasuryEntries: BexioEntry[];
  initialTreasury: number;
}

export const initialState: AccountingState = {
  accountHierarchy: null,
  openedAccounts: [],
  companyInfo: null,
  currencies: [],
  accountGroups: [],
  accounts: [],
  accountingMode: ACCOUNTING_MODE.operating,
  treasuryEntries: [],
  initialTreasury: 0,
};

export const accountingReducer = createReducer(
  initialState,
  on(userSignOutSuccess, (): AccountingState => initialState),
  on(getReferenceDataSuccess, (state, { company, currencies, accounts, accountGroups }): AccountingState => {
    return {
      ...state,
      companyInfo: company,
      currencies,
      accounts,
      accountGroups
    };
  }),
  on(getAccountHierarchySuccess, (state, { root }): AccountingState => ({ ...state, accountHierarchy: root })),
  on(toggleOpenedStatus, (state, { accountNo }): AccountingState => {
    let updatedOpenedAccounts: number[];
    const opened = state.openedAccounts.includes(accountNo);
    if (opened) {
      updatedOpenedAccounts = state.openedAccounts.filter(no => accountNo !== no);
    } else {
      const accountStr: string = "" + accountNo;
      updatedOpenedAccounts = [...state.openedAccounts, ...state.accountGroups.filter(accountGroup => accountGroup.account_no.startsWith(accountStr)).map(group => +group.account_no)];
    }
    return {
      ...state, openedAccounts: updatedOpenedAccounts
    }
  }),
  on(displayIntermediaryAccountGroupsChanged, (state, { enabled }): AccountingState => {
    if (!enabled) {
      const openedMainAccountGroups = state.openedAccounts.filter(accountNo => accountNo < 10);
      return ({
        ...state, openedAccounts: state.accountGroups.map(accountGroup => +accountGroup.account_no).filter(accountNo => openedMainAccountGroups.includes(+String(accountNo)[0]))
      });
    }
    return state;
  }),
  on(openAllRows, (state): AccountingState => ({
    ...state, openedAccounts: state.accountGroups.map(accountGroup => +accountGroup.account_no).filter(accountNo => accountNo < 1000)
  })),
  on(openFirstLevel, (state): AccountingState => ({
    ...state, openedAccounts: state.accountGroups.map(accountGroup => +accountGroup.account_no).filter(accountNo => accountNo < 10)
  })),
  on(closeAllRows, (state): AccountingState => ({
    ...state, openedAccounts: []
  })),
  on(setAccountingMode, (state, { mode }): AccountingState => ({
    ...state, accountingMode: mode
  })),
  on(getTreasuryEntriesForCurrentBusinessYearSuccess, (state, { entries }): AccountingState => ({...state, treasuryEntries: entries})),
  on(setInitialTreasury, (state, { initialTreasury }): AccountingState => ({
    ...state, initialTreasury
  })),
);

export const reducer = (state: AccountingState | undefined, action: Action): AccountingState => accountingReducer(state, action);
