<ng-container *ngIf="yearMonth$ | async as yearMonths">
  <ng-scrollbar [ngClass]="{ 'loading': loading }" autoHeightDisabled="false" visibility="hover" track="all"
                class="wrapper loading-component" *transloco="let t; read: 'financial-analysis'">
    <table *ngIf="settings$ | async as settings " (click)="hideCellAction()" #flowTable>
      <thead [ngClass]="{ 'fixed': tHeadFixed, 'mix': displayMode === DISPLAY_MODE_MIX }">
        <tr class="months">
          <th [ngClass]="{ 'active': tFirstColFixed }" class="first-col first-cell" rowspan="2">
            <div class="menu-container">
              <span>{{ t('table-title') }}</span>
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
            <mat-menu #menu="matMenu" xPosition="before" style="padding: 24px;">
              <div class="menu-content">
                <span class="menu-title">{{ t('menu-title-view') }}</span>
                <section>
                  <mat-checkbox color="primary" [checked]="settings.displayOnlyFilledRow"
                                (change)="displayOnlyFilledRowChanged($event.checked)">{{ t('display-only-filled-rows') }}</mat-checkbox>
                </section>
                <section>
                  <mat-checkbox color="primary" [checked]="settings.displayIntermediaryAccountGroups"
                                (change)="displayIntermediaryAccountGroupsChanged($event.checked)">{{ t('display-intermediary-account-groups') }}</mat-checkbox>
                </section>
                <button mat-stroked-button (click)="openFirstLevelClicked()" [disabled]="!settings.displayIntermediaryAccountGroups">{{ t('open-first-level-cells') }}</button>
                <button mat-stroked-button (click)="openAllRowsClicked()">{{ t('open-all-cells') }}</button>
                <button mat-stroked-button (click)="closeAllRowsClicked()">{{ t('close-all-cells') }}</button>
                <div *ngIf="features.csvExport">
                  <hr/>
                  <span class="menu-title">{{ t('menu-title-data') }}</span>
                  <button mat-stroked-button (click)="downloadCsv.emit()">{{ t('export-csv') }}</button>
                  <button mat-stroked-button disabled>{{ t('import-csv') }}</button>
                </div>
              </div>
            </mat-menu>
          </th>
          <th *ngFor="let yearMonth of yearMonths; let last = last" [ngClass]="{ 'last': last }" class="year-month" colspan="2" scope="colgroup">{{ yearMonth | date:'MMM yyyy': undefined : (locale$ | async) ?? undefined }}</th>
          <th [ngClass]="{ 'active': tLastColFixed }" class="last-col" colspan="2">Total</th>
        </tr>
        <tr [ngClass]="{ 'hidden': displayMode !== DISPLAY_MODE_MIX }">
          <ng-container *ngFor="let yearMonth of yearMonths; let last = last">
            <th scope="col">{{ t('real') }}</th>
            <th [ngClass]="{ 'last': last }" scope="col" class="budget">{{ t('budget') }}</th>
          </ng-container>
          <th [ngClass]="{ 'active': tLastColFixed, 'mix': displayMode === DISPLAY_MODE_MIX }" scope="col" class="last-col">{{ t('real') }}</th>
          <th [ngClass]="{ 'active': tLastColFixed && displayMode === DISPLAY_MODE_REAL }" scope="col" class="last-col budget">{{ t('budget') }}</th>
        </tr>
      </thead>
      <tbody [ngClass]="{ 'fixed': tFooterFixed }">
        <ng-container  *ngFor="let realFlowTableRow of realSourceTable; let rowIndex = index;">
          <tr *ngIf="realFlowTableRow.rowType === 'heading'; else groupAccountRow" class="category-header">
            <th scope="colgroup" class="first-col" [matTooltip]="realFlowTableRow.name" matTooltipPosition="right">
              <span>{{ t(realFlowTableRow.name) }}</span>
            </th>
            <th [attr.colspan]="(yearMonths.length + 1) * 2" class="category-header"></th>
          </tr>
          <ng-template #groupAccountRow>
            <tr *ngIf="realFlowTableRow.accountNo < 10 || ((realFlowTableRow.accountNo >= 1000 || settings.displayIntermediaryAccountGroups) && (!settings.displayOnlyFilledRow || realFlowTableRow.total !== 0 || budgetSourceTable[rowIndex].total !== 0))"
                [class]="realFlowTableRow.level + ' cat-' + realFlowTableRow.cat + ' type-' + realFlowTableRow.type"
                [ngClass]="{ 'opened': realFlowTableRow | opened:realSourceTable:rowIndex }">
              <th class="cat first-col"
                  (click)="toggleOpenNode(realFlowTableRow.accountNo)"
                  [matTooltip]="realFlowTableRow.name"
                  matTooltipPosition="right"
                  [ngClass]="{ 'has-children': realFlowTableRow.children.length > 0 && realFlowTableRow.type !== 'variation', 'active': tFirstColFixed, 'budget': displayMode === DISPLAY_MODE_BUDGET }" scope="row">
                <span *ngIf="realFlowTableRow.level === 'fourth-level'; else noColor" class="cat" [class]="'type-' + realFlowTableRow.type" [ngClass]="{'not-intermediary': !settings.displayIntermediaryAccountGroups}">
                  {{ realFlowTableRow.accountNo }} - {{ realFlowTableRow.name }}
                </span>
                <ng-template #noColor>
                  <p *ngIf="realFlowTableRow.accountNo">{{ realFlowTableRow.accountNo }} - {{ realFlowTableRow.name }}</p>
                  <p *ngIf="!realFlowTableRow.accountNo">{{ t(realFlowTableRow.name) }}</p>
                </ng-template>
                <ng-container *ngIf="(!settings.displayOnlyFilledRow || realFlowTableRow.total !== 0 || budgetSourceTable[rowIndex].total !== 0) && realFlowTableRow.level !== 'fourth-level' && realFlowTableRow.type !== 'variation'">
                  <mat-icon *ngIf="realFlowTableRow.opened">keyboard_arrow_up</mat-icon>
                  <mat-icon *ngIf="!realFlowTableRow.opened">keyboard_arrow_down</mat-icon>
                </ng-container>
              </th>
              <ng-container *ngIf="realFlowTableRow.rowType !== 'heading'">
                <ng-container *ngFor="let yearMonth of yearMonths; let yearMonthIndex = index; let last = last">
                  <!-- TODO: Directive for ngClass and currency pipe -->
                  <td class="real" [ngClass]="{ 'hidden': displayMode === DISPLAY_MODE_BUDGET, 'zero': realFlowTableRow.values[yearMonthIndex] === 0, 'negative': realFlowTableRow.values[yearMonthIndex] < 0, 'last': last && displayMode === DISPLAY_MODE_REAL }">
                    {{ realFlowTableRow.values[yearMonthIndex] | chf }}
                  </td>
                  <td class="budget" [ngClass]="{ 'hidden': displayMode === DISPLAY_MODE_REAL, 'zero': budgetSourceTable[rowIndex].values[yearMonthIndex] === 0, 'negative': budgetSourceTable[rowIndex].values[yearMonthIndex] < 0, 'last': last && displayMode !== DISPLAY_MODE_REAL }">
                    <ng-container *ngIf="realFlowTableRow.level !== 'fourth-level'; else editable">{{ budgetSourceTable[rowIndex].values[yearMonthIndex] | chf }}</ng-container>
                    <ng-template #editable>
                      <app-amount-input [amount]="budgetSourceTable[rowIndex].values[yearMonthIndex]" (userInput)="showCellAction(yearMonth, realFlowTableRow.accountNo.toString(), $event)" (amountChange)="updateBudgetAmount(yearMonth, realFlowTableRow.accountNo.toString(), $event)"></app-amount-input>
                    </ng-template>
                  </td>
                </ng-container>
                <th [ngClass]="{ 'hidden': displayMode === DISPLAY_MODE_BUDGET, 'zero': !realFlowTableRow?.total, 'negative': realFlowTableRow?.total < 0, 'active': tLastColFixed, 'mix': displayMode === DISPLAY_MODE_MIX }"
                    class="last-col">
                  {{ realFlowTableRow.total | chf }}
                </th>
                <th [ngClass]="{ 'hidden': displayMode === DISPLAY_MODE_REAL, 'zero': budgetSourceTable[rowIndex].total === 0, 'negative': budgetSourceTable[rowIndex].total < 0, 'active': tLastColFixed && displayMode === DISPLAY_MODE_BUDGET }"
                    class="last-col budget">
                  {{ budgetSourceTable[rowIndex].total | chf }}
                </th>
              </ng-container>
            </tr>
          </ng-template>
        </ng-container>
      </tbody>
    </table>
    <div *ngIf="actionButtonPosition" [style.left.px]="actionButtonPosition.left" [style.top.px]="actionButtonPosition.top" class="edit-cell-actions">
      <button mat-flat-button color="primary" (click)="fillRow($event)">{{ t('edit-cell-action.apply') }}</button>
      <mat-form-field appearance="outline">
        <mat-select [(value)]="repeatMonthlySelection">
          <mat-option *ngFor="let repeatOption of repeatMonthlyList" [value]="repeatOption">
            {{ t('edit-cell-action.repeat-monthly', { option: repeatOption }) }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </ng-scrollbar>
</ng-container>
