import { State } from '../../core/reducers';
import { createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { formatDate } from '@angular/common';
import { selectYearMonthSpan } from './span.selector';
import { selectMonthlyEntriesPerYearMonth, selectMonthlyEntriesYearMonths } from './monthly-entries.reducer';
import { YearMonthEntries } from '../model/bexio.model';

export const getMonthlyEntriesState = (state: State) => state.monthlyEntriesState;

export const selectEntriesLoading = createSelector(getMonthlyEntriesState, (state) => state.entriesLoading);

export const selectMonthlyEntries = createSelector(
  getMonthlyEntriesState,
  selectMonthlyEntriesPerYearMonth
);

export const selectMonths = createSelector(
  getMonthlyEntriesState,
  selectMonthlyEntriesYearMonths
)

export const selectExistingYearMonths = createSelector(
  selectYearMonthSpan,
  selectMonths,
  (allYearMonths: string[], existingYearMonths: string[] | number[]) => {
    const yearMonthsString = existingYearMonths.map(yearMonth => yearMonth.toString());
    return allYearMonths.filter(yearMonth => yearMonthsString.includes(yearMonth));
  });

export const selectExistingYearMonthsFormatted = createSelector(
  selectExistingYearMonths,
  (existingYearMonths: string[]) => {
    return existingYearMonths.map(yearMonth => formatDate(yearMonth, 'MMM yyyy', 'fr-CH'));
  });

export const selectMissingYearMonths = createSelector(
  selectYearMonthSpan,
  selectMonths,
  (allYearMonths: string[], existingYearMonths: string[] | number[]) => {
    const yearMonthsString = existingYearMonths.map(yearMonth => yearMonth.toString());
    return allYearMonths.filter(yearMonth => !yearMonthsString.includes(yearMonth));
  });

export const selectMonthlyEntriesWithinExistingSpan = createSelector(
  selectYearMonthSpan,
  selectMonthlyEntries,
  (yearMonths: string[], monthlyEntries: Dictionary<YearMonthEntries>): YearMonthEntries[] => {
    return yearMonths.reduce((entries: YearMonthEntries[], yearMonth) => {
      const theMonthlyEntries = monthlyEntries[yearMonth];
      if (theMonthlyEntries) {
        entries.push(theMonthlyEntries);
      }
      return entries;
    }, []);
  });
