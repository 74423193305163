import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { combineLatest, map, Observable } from 'rxjs';
import { selectExistingYearMonths } from '../store/monthly-entries.selector';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(
    private store: Store<any>,
    private translocoLocaleService: TranslocoLocaleService,
  ) {
  }

  getFormattedYearMonth(): Observable<string[]> {
    return combineLatest([
      this.store.select(selectExistingYearMonths),
      this.translocoLocaleService.localeChanges$
    ]).pipe(map(([yearMonths, locale]) =>
      yearMonths.map((yearMonth: string) => formatDate(yearMonth, 'MMM yyyy', locale))
    ));
  }
}
