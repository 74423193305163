import { MonthlyGraphData } from '../model/flow-table-row.model';
import { Color } from '../model/color.model';
import { draw } from 'patternomaly';
import { AccountType } from '../model/accounting.model';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';

export type graphType = 'line' | 'bar' | 'radar';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(
    private translocoService: TranslocoService,
  ) {
  }

  createDataset = async (
    flow: MonthlyGraphData,
    cumulated: boolean,
    inputColor: Color,
    inputText: string,
    outputColor: Color,
    outputText: string,
    resultColor: Color,
    resultText: string,
    yearMonths: string[] | null = null
  ) => {
    await firstValueFrom(this.translocoService.load(this.translocoService.getActiveLang()));
    return [
      this.createDatasetForResult(resultText, cumulated ? flow.treasury : flow.result, resultColor, yearMonths ? this.defineResultLengthToDisplay(yearMonths) : null),
      this.createDatasetForInputOutput(inputText, flow.input, inputColor, AccountType.input, yearMonths != null),
      this.createDatasetForInputOutput(outputText, flow.output, outputColor, AccountType.output, yearMonths != null)
    ];
  };

  defineResultLengthToDisplay = (yearMonths: string[]) => {
    const index = yearMonths.indexOf(formatDate(new Date(), 'MMM yyyy', 'fr-CH'))
    if (index >= 0) {
      return index + 1;
    }
    return yearMonths.length;
  }

  createDatasetForResult = (label: string, data: number[], color: Color, realResultLength: number | null = null) => ({
    type: 'line' as graphType,
    label: this.translocoService.translate(label),
    data: realResultLength ? data.slice(0, realResultLength) : data,
    borderColor: color.toRGB(),
    backgroundColor: color.toRGB(),
    borderWidth: 3,
    borderDash: realResultLength != null ? undefined : [5, 5],
    pointBackgroundColor: 'white',
    pointBorderWidth: 2,
    pointRadius: 5,
    tension: 0.3,
  });

  createDatasetForInputOutput = (label: string, data: number[], color: Color, accountType: AccountType, isReal: boolean) => ({
    type: 'bar' as graphType,
    label: this.translocoService.translate(label),
    data,
    backgroundColor: isReal ? color.toRGBA(0.5) : draw('diagonal', color.toRGBA(0.5)),
    barPercentage: 0.7,
    borderColor: color.toRGB(),
    borderRadius: 2,
    stack: accountType,
  });

}
