import { Component } from '@angular/core';
import { AuthenticationService } from '../../../authentication/authentication.service';
import { ActivatedRoute } from '@angular/router';
import { AnalyticsService } from '../../../shared/service/analytics.service';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartupComponent {

  constructor(
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private route: ActivatedRoute,
  ) {
    const referral = this.route.snapshot.data['referral'];
    if (referral) {
      this.localStorageService.storeReferralCode(referral)
      this.analyticsService.logEvent('referral', referral);
    }
  }

  async connectWithBexio() {
    this.analyticsService.logEvent('connect_with_bexio_clicked');
    await this.authenticationService.loginWithBexio();
  }
}
