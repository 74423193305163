import { createAction, props } from '@ngrx/store';
import { Profile, Settings } from '../model/profile.model';

export const createCheckoutSession = createAction(
  '[Profile] create checkout session',
  props<{ monthly: boolean }>()
);

export const createCheckoutSessionSuccess = createAction(
  '[Profile] create checkout session success'
)

export const createCheckoutSessionError = createAction(
  '[Profile] create checkout session success',
  props<{ error: Error }>()
)

export const checkCompanySubscription = createAction(
  '[Profile] check company subscription',
);

export const checkCompanySubscriptionSuccess = createAction(
  '[Profile] check company subscription success',
  props<{ companySubscriptionValid: boolean }>()
);

export const checkCompanySubscriptionError = createAction(
  '[Profile] check company subscription error',
  props<{ error: Error }>()
);

export const checkAccessToPaidFeatures = createAction(
  '[Profile] check access to paid features',
);

export const checkAccessToPaidFeaturesSuccess = createAction(
  '[Profile] check access to paid features success',
  props<{ accessToPaidFeatures: boolean }>()
);

export const checkAccessToPaidFeaturesError = createAction(
  '[Profile] check access to paid features error',
  props<{ error: Error }>()
);

export const getUserProfileSuccess = createAction(
  '[Profile] get user profile',
  props<{ userProfile: Profile }>()
)

export const settingsChanged = createAction(
  '[Profile] user settings changed',
  props<{ userSettings: Partial<Settings> }>()
)

export const settingsChangedSuccess = createAction(
  '[Profile] user settings changed success',
  props<{ userSettings: Partial<Settings> }>()
)

export const settingsChangedError = createAction(
  '[Profile] user settings changed error',
  props<{ error: Error }>()
)

export const paymentCancelled = createAction(
  '[Profile] payment cancelled'
)
