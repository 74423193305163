import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FlowTableRow } from '../../model/flow-table-row.model';
import { Store } from '@ngrx/store';
import {
  closeAllRows,
  openAllRows,
  openFirstLevel,
  toggleOpenedStatus
} from '../../store/accounting.action';
import { DisplayMode } from '../../model/display-mode.model';
import { selectExistingYearMonths } from '../../store/monthly-accounts.selector';
import { setScenarioValue, setScenarioValues } from '../../store/scenario.action';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { environment } from "../../../../environments/environment";
import { NgScrollbar } from "ngx-scrollbar";
import { Subscription, take, tap } from "rxjs";
import { MonthlyAccount } from "../../model/accounting.model";
import { selectSettings } from '../../../profile/store/profile.selector';
import { settingsChanged } from '../../../profile/store/profile.actions';

@Component({
  selector: 'app-flow-table',
  templateUrl: './flow-table.component.html',
  styleUrls: ['./flow-table.component.scss']
})
export class FlowTableComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild(NgScrollbar) scrollbarRef: NgScrollbar | undefined;
  @ViewChild('flowTable') flowTable?: ElementRef;
  parentComponent: HTMLElement | null = null;

  @Input() loading = true;
  @Input() realDataSource?: FlowTableRow[];
  @Input() budgetDataSource?: FlowTableRow[];
  @Input() displayMode: DisplayMode = DisplayMode.mix;
  @Output() downloadCsv = new EventEmitter<void>();

  settings$ = this.store.select(selectSettings);
  yearMonth$ = this.store.select(selectExistingYearMonths);
  locale$ = this.translocoLocaleService.localeChanges$;
  tHeadFixed: boolean = false;
  tFooterFixed: boolean = false;
  tFirstColFixed: boolean = false;
  tLastColFixed: boolean = true;
  actionButtonPosition: DOMRect | null = null;
  monthlyAccount: MonthlyAccount | null = null;
  repeatMonthlyList = [ 1, 2, 3, 6, 12 ];
  repeatMonthlySelection = 1;
  realSourceTable: FlowTableRow[] = [];
  budgetSourceTable: FlowTableRow[] = [];
  DISPLAY_MODE_REAL = DisplayMode.real;
  DISPLAY_MODE_MIX = DisplayMode.mix;
  DISPLAY_MODE_BUDGET = DisplayMode.budget;
  scrollSubscription: Subscription | undefined;
  features = environment.features;

  constructor(
    private store: Store<any>,
    private changeDetectorRef: ChangeDetectorRef,
    private translocoLocaleService: TranslocoLocaleService,
  ) {
  }

  ngAfterViewInit(): void {
    this.parentComponent = document.querySelector('app-accounting');
    this.scrollSubscription = this.scrollbarRef?.scrolled.pipe(tap((e: any) => this.onWrapperScroll(e.target))).subscribe();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.realDataSource !== undefined && this.budgetDataSource !== undefined && (changes['realDataSource'] || changes['budgetDataSource'])) {
      this.realSourceTable = this.realDataSource.map(row => this.flattenChildren(row)).flat();
      this.budgetSourceTable = this.budgetDataSource.map(row => this.flattenChildren(row)).flat();
    }
  }

  ngOnDestroy(): void {
    this.scrollSubscription?.unsubscribe();
  }

  onWrapperScroll(target: any) {
    // Vertical scroll
    if (target?.scrollTop !== undefined && this.parentComponent) {
      const wrapperRect = target.getBoundingClientRect();
      const tableRect = target.children[0].getBoundingClientRect();
      this.tHeadFixed = target.scrollTop > 0;
      this.tFooterFixed = target.scrollTop < (target.scrollHeight - target.offsetHeight);
      if (this.tHeadFixed && target.scrollTop < (tableRect.height - wrapperRect.height)) {
        const tableTopPos = +getComputedStyle(document.documentElement).getPropertyValue('--table-top-position').slice(0, -2);
        this.parentComponent.scroll({ top: this.parentComponent.scrollTop + wrapperRect.top - tableTopPos });
      }
    }

    // Horizontal scroll
    if (target?.scrollLeft !== undefined) {
      this.tFirstColFixed = target.scrollLeft > 0;
      this.tLastColFixed = target.scrollLeft < (target.scrollWidth - target.offsetWidth);
    }
  }

  flattenChildren = (flowTableRow: FlowTableRow): FlowTableRow[] => {
    if (!flowTableRow.opened || flowTableRow.children.length === 0) {
      return [flowTableRow];
    } else {
      return flowTableRow.children.reduce((acc: FlowTableRow[], cur) => acc.concat(this.flattenChildren(cur)), [flowTableRow]);
    }
  }

  showCellAction = (yearMonth: string, account_no: string, event: PointerEvent) => {
    if (event.target instanceof HTMLInputElement && this.flowTable) {
      event.stopPropagation();
      this.monthlyAccount = {
        yearMonth,
        accountValue: {
          account_no,
          value: +event.target.value
        }
      }
      const flowTableRect = this.flowTable.nativeElement.getBoundingClientRect();
      const inputRect = event.target.getBoundingClientRect();
      this.actionButtonPosition = new DOMRect(
        inputRect.x + inputRect.width - flowTableRect.x,
        inputRect.y - flowTableRect.y,
        inputRect.width,
        inputRect.height
      );
    }
  }

  hideCellAction = () => {
    this.actionButtonPosition = null;
  }

  fillRow = (event: Event) => {
    event.stopPropagation();
    this.yearMonth$.pipe(take(1)).subscribe(yearMonths => {
      if (this.monthlyAccount) {
        const monthlyAccountList: MonthlyAccount[] = yearMonths.slice(yearMonths.indexOf(this.monthlyAccount.yearMonth))
          .filter((yearMonth, index) => index % this.repeatMonthlySelection === 0)
          .map(yearMonth => ({
            yearMonth,
            accountValue: {
              account_no: this.monthlyAccount?.accountValue.account_no ?? '',
              value: this.monthlyAccount?.accountValue.value ?? 0
            }
          }));
        this.store.dispatch(setScenarioValues({ monthlyAccountList }));
        this.monthlyAccount = null;
        this.hideCellAction();
      }
    });
  }

  updateBudgetAmount = (yearMonth: string, account_no: string, value: number) => {
    this.store.dispatch(setScenarioValue({ monthlyAccount: { yearMonth, accountValue: { account_no, value }}}));
    this.monthlyAccount = {
      yearMonth,
      accountValue: {
        account_no,
        value
      }
    }
  }

  toggleOpenNode(accountNo: number): void {
    if (accountNo < 1000) {
      this.store.dispatch(toggleOpenedStatus({ accountNo }));
    }
  }

  displayOnlyFilledRowChanged(displayOnlyFilledRow: boolean): void {
    this.store.dispatch(settingsChanged({ userSettings: { displayOnlyFilledRow } }));
  }

  displayIntermediaryAccountGroupsChanged(displayIntermediaryAccountGroups: boolean): void {
    this.store.dispatch(settingsChanged({ userSettings: { displayIntermediaryAccountGroups } }));
  }

  openAllRowsClicked(): void {
    this.store.dispatch(openAllRows());
  }

  openFirstLevelClicked(): void {
    this.store.dispatch(openFirstLevel());
  }

  closeAllRowsClicked(): void {
    this.store.dispatch(closeAllRows());
  }
}
