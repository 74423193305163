<app-header [title]="(title$ | async)" beta="true"></app-header>
<ng-container *ngIf="cashFlowGraphData$ | async as cashFlowGraphData">
  <ng-container *transloco="let t; read: 'cash-flow'">
    <div class="indicators">
      <app-mini-chart [name]="t('input')" [dataSource]="cashFlowGraphData.input" [yearMonths]="yearMonths$ | async"
                      [color]="inputColor" [loading]="loading$ | async" cumulated="false"></app-mini-chart>
      <app-mini-chart [name]="t('output')" [dataSource]="cashFlowGraphData.output" [yearMonths]="yearMonths$ | async"
                      [color]="outputColor" [loading]="loading$ | async" cumulated="false"></app-mini-chart>
      <app-mini-chart [name]="t('variation')" [dataSource]="cashFlowGraphData.result"
                      [yearMonths]="yearMonths$ | async" [color]="variationColor" [loading]="loading$ | async"
                      cumulated="true"></app-mini-chart>
    </div>
    <main>
      <nav>
        <div class="container">
            <div class="content">
              <div class="content-left">
                <mat-icon class="error" *ngIf="(initialTreasuryValid$ | async) === false"
                          [matTooltip]="t('invalid-initial-treasury')">error</mat-icon>
                <div>
                  <div class="data-value">{{ initialTreasury$ | async | chf }}</div>
                  <div class="data-subtitle">{{ t('initial-treasury') }}</div>
                </div>
                <mat-icon class="arrow">arrow_right_alt</mat-icon>
                <div>
                  <div class="data-value">{{ cashFlowGraphData.treasury[cashFlowGraphData.treasury.length-1] | chf }}</div>
                  <div class="data-subtitle">{{ t('final-treasury') }}</div>
                </div>
              </div>
              <div class="content-right">
                <app-period-selector [startYearMonth]="startYearMonth$ | async"
                                     [endYearMonth]="endYearMonth$ | async"
                                     (startYearMonthChange)="changeStartYearMonth($event)"
                                     (endYearMonthChange)="changeEndYearMonth($event)"
                ></app-period-selector>
              </div>
            </div>
        </div>
      </nav>
      <div class="container">
        <app-chart [realInputOutput]="cashFlowGraphData"
                   [yearMonths]="yearMonths$ | async"
                   [inputColor]="inputColor"
                   inputText="cash-flow.input"
                   [outputColor]="outputColor"
                   outputText="cash-flow.output"
                   [resultColor]="variationColor"
                   resultText="cash-flow.variation"
                   [translocoChange]="locale$ | async"
                   [loading]="loading$ | async"
        ></app-chart>
      </div>
    </main>
  </ng-container>
</ng-container>
<app-footer></app-footer>
