import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, OAuthProvider } from '@firebase/auth';
import { firstValueFrom, from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { User } from './model/user.model';
import { LocalStorageService } from '../home/services/local-storage.service';
import { signInWithPopup } from '@angular/fire/auth';
import { userSignInWithBexioSuccess, userSignOutSuccess } from './store/authentication.action';
import { Store } from '@ngrx/store';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Profile } from "./model/profile.model";
import { NotificationService } from '../shared/service/notification.service';
import { TranslocoService } from '@ngneat/transloco';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private auth: AngularFireAuth,
    private firestore: AngularFirestore,
    private oAuthProvider: OAuthProvider,
    private localStorageService: LocalStorageService,
    private translocoService: TranslocoService,
    private notificationService: NotificationService,
    private store: Store,
  ) {
    this.oAuthProvider.addScope('openid');
    this.oAuthProvider.addScope('profile');
    this.oAuthProvider.addScope('accounting');
    this.oAuthProvider.addScope('offline_access');
  }

  loginWithBexio = async (): Promise<void> => {
    try {
      const result = await signInWithPopup(getAuth(), this.oAuthProvider) as any;
      const accessToken = result._tokenResponse.oauthAccessToken;
      this.localStorageService.storeAccessToken(accessToken);
      const user = await firstValueFrom(this.getCurrentUser());
      this.store.dispatch(userSignInWithBexioSuccess({ user }));
    } catch (error: any) {
      if (['auth/popup-closed-by-user', 'auth/cancelled-popup-request'].includes(error?.code)) {
        return;
      }
      if (error?.code === 'auth/popup-blocked') {
        const message = this.translocoService.translate('errors.auth-popup-blocked');
        this.notificationService.flashWarning(message);
        return;
      }
      const message = this.translocoService.translate('errors.unknown');
      this.notificationService.flashWarning(message);
      throw error;
    }
  };

  signOut(): Observable<void> {
    return from(this.auth.signOut().then(
      () => {
        this.localStorageService.destroyIdpInfo();
        this.store.dispatch(userSignOutSuccess());
      }
    ));
  }

  getCurrentUser(): Observable<User> {
    return from(this.auth.authState).pipe(
      mergeMap(() => {
        return from(this.auth.currentUser).pipe(
          mergeMap(firebaseUser => {
            if (firebaseUser !== null) {
              return this.getUser(firebaseUser.uid);
            }
            throw new Error('not logged in');
          })
        );
      })
    );
  }

  async getUser(userId: string): Promise<User> {
    const profileDoc = await firstValueFrom(this.firestore.collection<Profile>('profiles').doc(userId).get());
    if (!profileDoc?.exists) {
      throw new Error(`user profile does not exist for user ${userId}`);
    }
    const profile = profileDoc.data()!;
    return {
      id: userId,
      email: profile.email,
      createdAt: profile.createdAt.toDate(),
    } as User;
  }
}
