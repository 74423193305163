import { BexioBusinessYear } from './bexio.model';

export interface YearMonth {
  year: number;
  month: number;
}

export const toYearMonthString = (yearMonth: YearMonth): string =>
  buildYearMonthString(yearMonth.year, yearMonth.month);

export const fromYearMonthString = (yearMonthString: string): YearMonth => {
  const [year, month] = yearMonthString.split('-');
  return {
    year: Number(year),
    month: Number(month) - 1,
  };
};

export const incrementYear = (yearMonthString: string): string => {
  const [year, month] = yearMonthString.split('-');
  return `${Number(year) + 1}-${month}`;
}

export const fromBusinessYear = (businessYear: BexioBusinessYear): string[] => {
  const startDate = new Date(businessYear.start);
  startDate.setHours(0);
  const endDate = new Date(businessYear.end);
  endDate.setHours(0);
  return getYearMonths(startDate, endDate);
}

export const buildYearMonthString = (year: number, month: number): string => {
  const paddedMonth = `${month + 1}`.padStart(2, '0');
  return `${year}-${paddedMonth}`;
};

export const buildYearMonthNumber = (year: number, month: number): number =>
  Number(`${year}${month.toString().padStart(2, '0')}`);

export const getYearMonths = (startDate: Date, endDate: Date): string[] => {
  const yearMonths: string[] = [];
  while (startDate.getTime() <= endDate.getTime()) {
    const month = startDate.getMonth();
    yearMonths.push(buildYearMonthString(startDate.getFullYear(), month))
    startDate.setMonth(month + 1);
  }
  return yearMonths;
};
