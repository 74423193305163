<app-header></app-header>
<main *transloco="let t; read: 'startup'">
  <div class="container">
    <h4 [innerHTML]="t('title')"></h4>
    <section>
      <p>{{ t('message') }}</p>
      <div class="actions">
        <button mat-flat-button color="primary" (click)="connectWithBexio()">{{ t('connect-with-bexio') }}</button>
      </div>
      <p class="small">{{ t('disclaimer') }}</p>
    </section>
    <div class="services">
      <div class="service">
        <h5 [innerHTML]="t('service-1-title')"></h5>
        <h6 [innerHTML]="t('service-1-subtitle')"></h6>
        <img src="assets/service1.png" alt="{{ t('service-1-title') }}">
        <p [innerHTML]="t('service-1-text')"></p>
      </div>
      <div class="service">
        <h5 [innerHTML]="t('service-2-title')"></h5>
        <h6 [innerHTML]="t('service-2-subtitle')"></h6>
        <img src="assets/service2.png" alt="{{ t('service-2-title') }}">
        <p [innerHTML]="t('service-2-text')"></p>
      </div>
      <div class="service">
        <h5 [innerHTML]="t('service-3-title')"></h5>
        <h6 [innerHTML]="t('service-3-subtitle')"></h6>
        <img src="assets/service3.png" alt="{{ t('service-3-title') }}">
        <p [innerHTML]="t('service-3-text')"></p>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
