import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Scenario } from '../../store/scenario.model';
import { DialogService } from '../../service/dialog.service';
import { RenameDialogComponent } from '../rename-dialog/rename-dialog.component';
import { CreateScenarioDialogComponent } from '../create-scenario-dialog/create-scenario-dialog.component';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-scenario-selector',
  templateUrl: './scenario-selector.component.html',
  styleUrls: ['./scenario-selector.component.scss']
})
export class ScenarioSelectorComponent implements OnChanges {

  @Input() scenarios: Scenario[] = [];
  @Input() selectedId: string | undefined = undefined;
  @Input() disabled: boolean = false;
  @Output() selectedIdChange = new EventEmitter<string>();
  @Output() scenarioIdRemoved = new EventEmitter<string>();
  @Output() scenarioRenamed = new EventEmitter<Scenario>();
  @Output() scenarioDuplicated = new EventEmitter<Scenario>();
  @Output() scenarioCreated = new EventEmitter<{ name: string, initDataWithPreviousYear: boolean }>();
  selectedValue: Scenario | undefined = undefined;

  constructor(
    private translocoService: TranslocoService,
    private dialogService: DialogService
  ) {
  }

  ngOnChanges(): void {
    if (this.selectedId && this.scenarios.length > 0) {
      this.selectedValue = this.scenarios.find(s => s.id === this.selectedId);
    } else {
      this.selectedValue = undefined;
    }
  }

  deleteScenario = async () => {
    if (this.selectedValue) {
      const title = this.translocoService.translate('dialog.budget.delete', { name: this.selectedValue.name });
      const message = this.translocoService.translate('dialog.budget.delete-warning', { name: this.selectedValue.name });
      const confirm = await this.dialogService.requestConfirmation(title, message);
      if (confirm) {
        this.scenarioIdRemoved.emit(this.selectedValue.id);
      }
    }
  };

  renameScenario = async () => {
    if (this.selectedValue) {
      const newName = await this.dialogService.openDialogComponent<string>(RenameDialogComponent, { name: this.selectedValue.name });
      if (newName) {
        this.scenarioRenamed.emit({ id: this.selectedValue.id, name: newName });
      }
    }
  };

  duplicateScenario = () => {
    if (this.selectedValue) {
      const newName = this.translocoService.translate('dialog.budget.copy', { name: this.selectedValue.name });
      this.scenarioDuplicated.emit({ id: this.selectedValue.id, name: newName });
    }
  }

  createNewScenario = async () => {
    const result = await this.dialogService.openDialogComponent<{
      name: string,
      initDataWithPreviousYear: boolean
    }>(CreateScenarioDialogComponent, { name: '' });
    if (result && result.name) {
      this.scenarioCreated.emit({ name: result.name, initDataWithPreviousYear: result.initDataWithPreviousYear });
    }
  }

}
