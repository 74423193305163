import { AfterViewInit, Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import { Color } from '../../model/color.model';
import { sum } from '../../../shared/utils/numbers';
import { ChartService } from '../../service/chart.service';

@Component({
  selector: 'app-mini-chart',
  template: `
    <div [ngClass]="{ 'loading': loading }" class="container loading-component">
      <div class="chart-container">
        <canvas #chart></canvas>
      </div>
      <div class="values" *ngIf="dataSource">
        <span [style]="'color:' + (color | rgb)">CHF {{ total ?? 0 | chf }}</span>
        <h3>{{ name }}</h3>
      </div>
    </div>
  `,
  styles: [`
    .chart-container {
      height: 128px;
      width: 100%;
    }

    canvas {
      margin: 8px 0;
    }

    .values {
      padding: 8px;

      span {
        font-size: 1.5em;
        margin: 0;
      }

      h3 {
        color: grey;
        margin: 0;
      }
    }
  `]
})
export class MiniChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('chart') canvas: ElementRef<HTMLCanvasElement> | undefined;

  @Input() loading = true;
  @Input() name?: string;
  @Input() dataSource: number[] = [0];
  @Input() yearMonths: string[] = [];
  @Input() color: Color = Color.buildBlue();
  @Input() cumulated = false;

  total: number = 0;
  chart: Chart | undefined;

  constructor(
    private chartService: ChartService,
  ) {
  }

  ngAfterViewInit(): void {
    this.createChart();
  }

  ngOnChanges(): void {
    this.createChart();
    this.total = this.cumulated ? sum(this.dataSource) : this.dataSource[this.dataSource.length - 1];
  }

  createChart(): void {
    if (this.canvas) {
      const datasetLengthToDisplay = this.chartService.defineResultLengthToDisplay(this.yearMonths);
      const minValue = Math.min.apply(null, this.dataSource);
      const maxValue = Math.max.apply(null, this.dataSource);
      const diffValue = maxValue - minValue;

      if (this.chart) {
        this.chart.destroy();
      }

      this.chart = new Chart(this.canvas.nativeElement, {
        type: 'line',
        plugins: [{
          id: 'test',
          afterLayout: chart => {
            if (chart.ctx) {
              chart.ctx.save();

              const gradient = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
              gradient.addColorStop(0, this.color.toRGBA(0.3));
              gradient.addColorStop(1, this.color.toRGBA(0));

              chart.data.datasets[0].backgroundColor = gradient;

              chart.ctx.restore();
            }
          }
        }],
        data: {
          labels: [...Array(datasetLengthToDisplay).keys()],
          datasets: [
            {
              data: this.dataSource.slice(0, datasetLengthToDisplay).map(value => value - minValue + (diffValue / 80)),
              borderColor: this.color.toRGB(),
              backgroundColor: this.color.toRGB(),
              borderWidth: 2,
              tension: 0.3,
              fill: true
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            point: {
              radius: 0
            }
          },
          scales: {
            y: {
              display: false,
              beginAtZero: true
            },
            x: {
              display: false
            }
          },
          plugins: {
            legend: {
              display: false
            }
          },
          events: [],
        }
      });
    }
  }
}
