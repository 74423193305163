import { createAction, props } from '@ngrx/store';
import { MonthlyAccount } from '../model/accounting.model';
import { Scenario } from './scenario.model';

export const getScenarios = createAction('[Scenario] get existing scenarios');
export const getScenariosSuccess = createAction('[Scenario] get existing scenarios success', props<{ scenarios: Scenario[] }>());
export const getScenariosFailure = createAction('[Scenario] get existing scenarios failure', props<{ error: Error }>());

export const getScenarioValues = createAction('[Scenario] get scenario values', props<{ scenarioId: string }>());
export const getScenarioValuesSuccess = createAction('[Scenario] get scenario values success', props<{ scenarioId: string, values: any }>());
export const getScenarioValuesFailure = createAction('[Scenario] get scenario values failure', props<{ error: Error }>());

export const setScenarioValue = createAction('[Scenario] set scenario value', props<{ monthlyAccount: MonthlyAccount }>());
export const setScenarioValueSuccess = createAction('[Scenario] set scenario value success', props<{ scenarioId: string; monthlyAccount: MonthlyAccount }>());
export const setScenarioValueFailure = createAction('[Scenario] set scenario value failure', props<{ error: Error }>());

export const setScenarioValues = createAction('[Scenario] set scenario values', props<{ monthlyAccountList: MonthlyAccount[] }>());
export const setScenarioValuesSuccess = createAction('[Scenario] set scenario values success', props<{ scenarioId: string; monthlyAccountList: MonthlyAccount[] }>());
export const setScenarioValuesFailure = createAction('[Scenario] set scenario values failure', props<{ error: Error }>());

export const deleteScenario = createAction('[Scenario] delete scenario', props<{ scenarioId: string }>());
export const deleteScenarioSuccess = createAction('[Scenario] delete scenario success', props<{ scenarioId: string }>());
export const deleteScenarioFailure = createAction('[Scenario] delete scenario failure', props<{ error: Error }>());

export const setScenarioName = createAction('[Scenario] set scenario name', props<{ scenarioId: string; newName: string }>());
export const setScenarioNameSuccess = createAction('[Scenario] set scenario name success', props<{ scenarioId: string; newName: string }>());
export const setScenarioNameFailure = createAction('[Scenario] set scenario name failure', props<{ error: Error }>());

export const duplicateScenario = createAction('[Scenario] duplicate scenario', props<{ scenarioId: string, scenarioName: string }>());
export const duplicateScenarioSuccess = createAction('[Scenario] duplicate scenario success', props<{ scenario: Scenario }>());
export const duplicateScenarioFailure = createAction('[Scenario] duplicate scenario failure', props<{ error: Error }>());

export const createScenario = createAction('[Scenario] create scenario', props<{ scenarioName: string, initDataWithPreviousYear: boolean }>());
export const createScenarioSuccess = createAction('[Scenario] create scenario success', props<{ scenario: Scenario }>());
export const createScenarioWithPreviousYearSuccess = createAction('[Scenario] create scenario with previous year success', props<{ scenario: Scenario }>());
export const createScenarioFailure = createAction('[Scenario] create scenario failure', props<{ error: Error }>());

export const downloadPreviousYearCsv = createAction('[Scenario] download previous year as CSV');
