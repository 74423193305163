<nav *transloco="let t">
  <a href="https://start.edelwise.app" target="_blank" class="logo">
    <img class="small-logo" src="../assets/logo-edelwise.png" alt="Logo Edelwise by LEAF Technology"/>
    <img class="large-logo" src="../assets/logo-edelwise-horizontal.png" alt="Logo Edelwise by LEAF Technology"/>
  </a>
  <div class="navigation">
    <button routerLink="/analysis"
            aria-label="{{ t('financial-analysis.title') }}"
            mat-button color="primary"
            [ngClass]="{ 'enabled': '/analysis' === (routeUrl$ | async) }"
            [disabled]="(companyInfo$ | async) === null">
      <mat-icon>analytics</mat-icon>
      <span class="nav-button-title">{{ t('financial-analysis.title') }}</span>
    </button>
    <button routerLink="/cash-flow"
            aria-label="{{ t('cash-flow.title') }}"
            mat-button color="primary"
            [ngClass]="{ 'enabled': '/cash-flow' === (routeUrl$ | async) }"
            [disabled]="(companyInfo$ | async) === null">
      <mat-icon>currency_exchange</mat-icon>
      <span class="nav-button-title">{{ t('cash-flow.title') }}</span>
    </button>
    <button *ngIf="features.valuation"
            routerLink="/valuation"
            aria-label="{{ t('valuation.title') }}"
            mat-button color="primary"
            [ngClass]="{ 'enabled': '/valuation' === (routeUrl$ | async) }"
            [disabled]="(companyInfo$ | async) === null">
      <mat-icon>store</mat-icon>
      <span class="nav-button-title">{{ t('valuation.title') }}</span>
    </button>
  </div>
  <a class="support" href="{{ supportUrl }}" target="_blank" aria-label="{{ t('support.title') }}" mat-button color="primary">
    <mat-icon>help</mat-icon>
    <span class="nav-button-title">{{ t('support.title') }}</span>
  </a>
</nav>
